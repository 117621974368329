import { ChargerDevice } from './types';

export const EV_ANYTIME_SMART_CHARGER_LINK =
  'https://www.ovoenergy.com/electric-cars/smart-charger';

// If you need to update SmartChargers remember to update the enum in the backend
// https://github.com/ovotech/charge-anytime-eligibility-backend/blob/main/src/types.ts
export enum SmartChargers {
  Indra = 'Indra',
  Ohme = 'Ohme',
  Hypervolt = 'Hypervolt',
  Andersen = 'Andersen',
  ThreePin = 'ThreePin',
  NotListed = 'NotListed',
}

export const noManufacturerChargerOptions = [
  {
    value: SmartChargers.ThreePin,
    text: 'I use a standard 3 pin plug',
  },
  {
    value: SmartChargers.NotListed,
    text: 'My charger isn’t listed',
  },
];

export const smartChargersOptions = [
  {
    value: SmartChargers.Indra,
    text: 'Indra: Smart PRO/Smart Charger V3',
  },
  {
    value: SmartChargers.Ohme,
    text: 'Ohme: Home/Home Pro/ePod',
  },
  {
    value: SmartChargers.Hypervolt,
    text: 'Hypervolt: Home 3.0/Home 3 Pro',
  },
  {
    value: SmartChargers.Andersen,
    text: 'Andersen: A2 (2024)/A3',
  },
  ...noManufacturerChargerOptions,
];

export const smartChargerSelections = (exclusions: Array<SmartChargers> = []) =>
  smartChargersOptions.filter((i) => !exclusions.includes(i.value));

export const generateChargerSelections = (
  chargers: ChargerDevice[],
  isChargeAnytimeDevicesActive = false
) => {
  if (!isChargeAnytimeDevicesActive) {
    return smartChargerSelections();
  }
  return chargers
    .reduce<[ChargerDevice['manufacturer'], ChargerDevice['model'][]][]>(
      (chargerModels, charger) => {
        const chargerModelIndex = chargerModels.findIndex(
          ([manufacturer]) => charger.manufacturer === manufacturer
        );
        const [manufacturer, models = []] =
          chargerModels[chargerModelIndex] ?? [];
        if (!manufacturer) {
          return [...chargerModels, [charger.manufacturer, [charger.model]]];
        }
        if (manufacturer) {
          chargerModels[chargerModelIndex] = [
            manufacturer,
            [...models, charger.model],
          ];
        }
        return chargerModels;
      },
      []
    )
    .map(([manufacturer, models]) => ({
      value: manufacturer,
      text: `${manufacturer}: ${models.join('/')}`,
    }))
    .concat(noManufacturerChargerOptions);
};
