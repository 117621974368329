import { Card } from '@ovotech/nebula';
import styled from 'styled-components';

export const CarbonIntensityCard = styled(Card)`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  align-items: center;
`;

export const CarbonIntensityWidget = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const CurrentIntensityIndicator = styled.div`
  display: block;
  width: 100%;
  padding: 12px 0;
  font-size: 16px;
`;
