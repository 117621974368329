import {
  Reason,
  StyledTitle,
} from '@/src/pages/AnytimeEligibility/pages/Fallout/Fallout.styled';
import { Margin, P, TextLink } from '@ovotech/nebula';
import { ReturnToHomePage } from '@/src/components/ReturnToHomePage/ReturnToHomePage';
import React from 'react';
import { AlreadyHasChargeAnytimeTitle } from '@/src/pages/AnytimeEligibility/pages/Fallout/components/AlreadyHasChargeAnytime';

export const FalloutAnytimeV1 = () => {
  return (
    <Reason data-testid="anytime-already-initialized">
      <AlreadyHasChargeAnytimeTitle />
      <P>
        Good news! There’s no need to check your compatibility again, you can
        get Charge Anytime. You just need to complete your set-up.
      </P>
      <StyledTitle>Ohme customers</StyledTitle>
      <p>
        Go to your{' '}
        <TextLink
          href={process.env.NX_EV_ANYTIME_OHME_IDENTITY_URL as string}
          opensInNewWindow
        >
          Ohme app
        </TextLink>{' '}
        to connect your charger with Charge Anytime. Once you’ve done this, you
        can control all your smart charging in your Ohme app.
      </p>
      <StyledTitle>Indra customers</StyledTitle>
      <p>
        Go to your{' '}
        <TextLink
          href={process.env.NX_EV_ANYTIME_KALUZA_IDENTITY_URL as string}
          opensInNewWindow
        >
          Indra app
        </TextLink>{' '}
        to connect your charger with Charge Anytime. Once you’ve done this, you
        can control all your smart charging in your Indra app.
      </p>
      <Margin top={2}>
        <ReturnToHomePage />
      </Margin>
    </Reason>
  );
};
