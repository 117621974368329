import { isVehicleEligible } from './anytime-vehicles';
import { SmartChargers } from './anytime-chargers';
import { EligibilityQuestions } from './types';

export type IneligibleReasons = {
  hasSolarPanels: boolean;
  incompatibleCar: boolean;
  hasNoInternetAccess: boolean;
  notSmartMeter: boolean;
  onlyGasSupply: boolean;
  loss: boolean;
  invalidOther: boolean;
  numberOfReasons: number;
};

export type useEligibilityCheckArgs = Omit<EligibilityQuestions, 'type'> & {
  ineligibleNotSmartMeter: boolean;
  ineligibleOnlyGas: boolean;
  ineligibleLoss: boolean;
  ineligibleOther: boolean;
  hasSmartMeter: boolean;
  isPayAsYouDriveEnabled: boolean;
  isPayAsYouDriveVolvoEnabled: boolean;
};

export type eligibilityResponse = {
  isEligible: boolean;
};

function removeFalseValues(obj: Record<string, unknown>) {
  return Object.fromEntries(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Object.entries(obj).filter(([_, value]) => !!value)
  );
}

export const useEligibilityCheck = ({
  deviceId,
  ineligibleOnlyGas,
  ineligibleLoss,
  ineligibleOther,
  ineligibleNotSmartMeter,
  year,
  hasSolarPanels,
  hasInternetAccess,
  smartCharger,
  hasSmartMeter,
  isPayAsYouDriveVolvoEnabled,
  isPayAsYouDriveEnabled,
}: useEligibilityCheckArgs): eligibilityResponse &
  Partial<IneligibleReasons> => {
  const isOhme = smartCharger === SmartChargers.Ohme;
  const eligibleCharger =
    (smartCharger &&
      [
        SmartChargers.Indra,
        SmartChargers.Hypervolt,
        SmartChargers.Andersen,
      ].includes(smartCharger)) ||
    (isOhme && !hasSolarPanels);

  const ineligibleReasons: IneligibleReasons = {
    incompatibleCar:
      !eligibleCharger &&
      !isVehicleEligible(deviceId, year, {
        isPayAsYouDriveVolvoEnabled,
      }) &&
      !isOhme,
    hasSolarPanels: !eligibleCharger && !!hasSolarPanels,
    hasNoInternetAccess: !eligibleCharger && !hasInternetAccess && !isOhme,
    notSmartMeter: isPayAsYouDriveEnabled
      ? ineligibleNotSmartMeter
      : !hasSmartMeter,
    onlyGasSupply: isPayAsYouDriveEnabled && ineligibleOnlyGas,
    loss: isPayAsYouDriveEnabled && ineligibleLoss,
    invalidOther: isPayAsYouDriveEnabled && ineligibleOther,
    numberOfReasons: 0,
  };

  ineligibleReasons.numberOfReasons = Object.values(ineligibleReasons).filter(
    (reason) => reason
  ).length;

  const trueIneligibleReasons = removeFalseValues(ineligibleReasons);

  if (ineligibleReasons.numberOfReasons === 0) {
    return { isEligible: true };
  } else if (ineligibleReasons.invalidOther) {
    return {
      isEligible: false,
      invalidOther: true,
    };
  } else {
    return {
      ...trueIneligibleReasons,
      isEligible: false,
    };
  }
};
