import { Col, Row } from '@ovotech/nebula';
import React, { ReactNode } from 'react';
import {
  SideNavigationWrapper,
  NavAwareCol,
} from './SideNavigationLayout.styled';
import SideNavigationContainer from '@/src/components/Navigation/SideNavigationContainer';
import useWindowDimensions from '@/src/utils/hooks/useWindowDimensions';

export const SideNavigationLayout = ({ children }: { children: ReactNode }) => {
  const { isDesktop } = useWindowDimensions({ debounceDelay: 0 });

  return (
    <Row data-testid="side-navigation-layout">
      {isDesktop && (
        <NavAwareCol as={Col} desktopNav={3}>
          <SideNavigationWrapper>
            <SideNavigationContainer />
          </SideNavigationWrapper>
        </NavAwareCol>
      )}

      <NavAwareCol as={Col} id="main" desktopNav={9}>
        {children}
      </NavAwareCol>
    </Row>
  );
};
