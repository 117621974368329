import {
  Card,
  Heading3,
  PrimaryCTAButton,
  Stack,
  Margin,
} from '@ovotech/nebula';
import {
  useAccountContext,
  useAnalytics,
  useCustomerContext,
} from '@ovotech/ui-tools';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom-v5';
import styled from 'styled-components';
import { StyledRadioContainer } from './SelectAccount.styled';
import SelectAccountWithData from './SelectAccountWithData';
import { useBootstrapQuery } from '@/src/api/kapi/use-bootstrap-query';
import { PageWithSimpleHeader, Section } from '@/src/components';
import ErrorSection from '@/src/components/ErrorSection/ErrorSection';
import { SkeletonCircle } from '@/src/components/SkeletonLoading/SkeletonCircle';
import { SkeletonText } from '@/src/components/SkeletonLoading/SkeletonText';
import { ACCOUNT_SELECTOR } from '@/src/constants/analytics-subjects';
import { SELECT_ACCOUNT_ERROR } from '@/src/constants/error-section-ids';
import { ROUTE_HOME } from '@/src/constants/routes';
import { selectAccount } from '@/src/redux/ducks/user';
import { saveSelectedAccountCookie } from '@/src/utils/account-selection';
import { sortByEarliestStartDate } from './utils';

const StyledLoadingContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledLoadingText = styled.div`
  ${({ theme: { space } }) => `
    width: 100%;
    margin-left: ${space[2]}
  `}
`;

const Loading = () => (
  <Margin bottom={1} data-testid="select-account-loading">
    <StyledLoadingContainer>
      <SkeletonCircle size="4rem" />
      <StyledLoadingText>
        <SkeletonText />
      </StyledLoadingText>
    </StyledLoadingContainer>
  </Margin>
);

const Error = () => (
  <Card>
    <ErrorSection
      alertLevel="error"
      id={SELECT_ACCOUNT_ERROR}
      data-testid={SELECT_ACCOUNT_ERROR}
    />
  </Card>
);

const SelectAccount = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { customerId } = useCustomerContext();

  const { dispatch: sendAnalytics } = useAnalytics();
  useEffect(() => {
    sendAnalytics({
      name: ACCOUNT_SELECTOR,
      type: 'view',
    });
  }, [sendAnalytics]);

  const { error, loading, data } = useBootstrapQuery(customerId);

  const [selectedAccount, setSelectedAccount] = useState(
    data ? data.selectedAccountId || data.accountIds[0] : '',
  );

  const handleChange = (value: string) => {
    setSelectedAccount(value);
  };

  const { setAccountId } = useAccountContext();

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  if (data) {
    const sortedAccounts = [...data.accounts].sort(sortByEarliestStartDate);
    return (
      <PageWithSimpleHeader title="Select account">
        <Section>
          <Card data-testid="select-account" as="fieldset">
            <Stack spaceBetween={4}>
              <Heading3 as="legend">Select an account</Heading3>
              <StyledRadioContainer>
                {sortedAccounts.map((account, index) => (
                  <SelectAccountWithData
                    key={index}
                    accountId={account.accountId}
                    selectedAccount={selectedAccount}
                    setSelectedAccount={handleChange}
                    supplyPoints={account.supplies}
                  />
                ))}
              </StyledRadioContainer>

              <PrimaryCTAButton
                onClick={() => {
                  saveSelectedAccountCookie(selectedAccount);
                  setAccountId(selectedAccount);
                  history.push(
                    (location?.state as { prevUrl?: string })?.prevUrl ??
                      ROUTE_HOME,
                  );
                  dispatch(selectAccount(selectedAccount));
                }}
              >
                Continue
              </PrimaryCTAButton>
            </Stack>
          </Card>
        </Section>
      </PageWithSimpleHeader>
    );
  }

  return <Error />;
};

export default SelectAccount;
