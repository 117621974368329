import { BootstrapResponse } from '@ovotech/energy-cx';
import { BootstrapAccount } from '@ovotech/energy-cx/dist/shared/api/legacy-queries/bootstrap/types';
import { addDays, isBefore, startOfToday } from 'date-fns';
import cookies from 'js-cookie';
import { FuelType } from '@/src/types/Account';
import { BootstrapAccountSupply } from '@/src/types/Response';
import {
  AccountSupplyPointFragment,
  BootstrapQuery,
} from '../kapi/__generated__/graphql';
import { SupplyFuel } from '@monovo/kapi';

type KapiAddress = { addressLines?: string[]; postCode?: string };
export const getAddress = ({
  addressLines,
  postCode,
}: {
  addressLines?: string[];
  postCode?: string;
}): string[] => {
  if (addressLines && addressLines.length) {
    const address = [...addressLines];
    if (postCode) {
      address.push(postCode);
    }
    return address;
  }

  return postCode ? [postCode] : [];
};

export const getFuelType = (kapiFuel: SupplyFuel): FuelType =>
  kapiFuel === 'ELECTRICITY' ? 'electricity' : 'gas';

export const isSupplyOnboarding = (supplyStartDate: string): boolean => {
  return isBefore(startOfToday(), addDays(new Date(supplyStartDate), 6));
};

export const getSupplyPointWithActiveMeters = (
  supply: AccountSupplyPointFragment,
): BootstrapAccountSupply => {
  /**
   *  Kapi is returning all the meters ("Removed/Active"), hence, a filtering is needed.
   *  This fixed this bug https://ovotech.atlassian.net/browse/ZPBB-3537
   */
  const activeMetersTechnicalDetails =
    supply.supplyPoint.meterTechnicalDetails.filter(
      ({ status }) => status === 'Active',
    );
  const hasActiveMeters = activeMetersTechnicalDetails.length > 0;
  const firstActiveMeter = activeMetersTechnicalDetails?.[0];
  /**
   *  Find a better way to return data based on all the meters for a supply point.
   *  Right now data is calculated based on the first item in meterTechnicalDetails
   */

  return {
    mpxn: supply.supplyPoint.sprn,
    fuel: getFuelType(supply.supplyPoint.fuelType),
    isPayg: hasActiveMeters
      ? firstActiveMeter?.mode?.toLowerCase() === 'prepayment'
        ? true
        : false
      : false,
    start: supply.startDate,
    supplyPointInfo: {
      meterType: hasActiveMeters ? firstActiveMeter.type ?? null : null,
      meterNotFound: hasActiveMeters
        ? !firstActiveMeter.meterSerialNumber
        : true,
      address:
        supply.supplyPoint && supply.supplyPoint.address
          ? getAddress(supply.supplyPoint.address as KapiAddress)
          : [],
    },
    isOnboarding: isSupplyOnboarding(supply.startDate),
  };
};

export const getAccounts = (
  kapiData: BootstrapQuery,
): Array<BootstrapAccount> => {
  return kapiData.customer_nextV1.customerAccountRelationships.edges.map(
    ({ node }) => {
      const supplyPoints = node.account.accountSupplyPoints
        ? node.account.accountSupplyPoints?.map(supplyPoint =>
            getSupplyPointWithActiveMeters(supplyPoint),
          )
        : [];

      return {
        accountId: node.account.accountNo,
        isPayg:
          supplyPoints && supplyPoints.length > 0
            ? supplyPoints[0].isPayg
            : false,
        supplies: supplyPoints,
      };
    },
  );
};

export const resolveSelectedAccountId = (
  accountIds: string[],
  selectedAccountIdCookie?: string,
): string | null => {
  if (accountIds.length === 1) {
    return accountIds[0];
  }
  if (selectedAccountIdCookie === null || !selectedAccountIdCookie) {
    return null;
  }
  return accountIds.includes(selectedAccountIdCookie)
    ? selectedAccountIdCookie
    : null;
};

export const getAccountIds = (kapiData: BootstrapQuery): string[] =>
  kapiData.customer_nextV1.customerAccountRelationships.edges.map(
    ({ node }) => node.account.accountNo,
  );

export const mapKapiToLegacy = (
  kapiData: BootstrapQuery,
): BootstrapResponse => {
  const selectedAccountCookie = cookies.get('selectedAccountId');
  const accountIds = getAccountIds(kapiData);

  return {
    selectedAccountId: resolveSelectedAccountId(
      accountIds,
      selectedAccountCookie,
    ),
    accountIds: accountIds,
    accounts: getAccounts(kapiData),
    customerId: kapiData.customer_nextV1.id,
    isFirstLogin: false,
  };
};
