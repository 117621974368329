import { useQuery } from 'react-query';
import { createProductsEndpoint } from '@/src/constants/endpoints';
import { ProductsResponse } from '@/src/types/Response';
import { apiService } from '@/src/services/api-service';

export const useProductsQuery = (accountId: string | null | undefined) =>
  useQuery({
    queryKey: `orion-products-${accountId}`,
    queryFn: () =>
      apiService.get<ProductsResponse>({
        url: createProductsEndpoint(accountId ?? ''),
      }),
    enabled: !!accountId,
    retryOnMount: false,
  });
