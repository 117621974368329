import { connectRouter, RouterState } from 'connected-react-router';
import { LocationState } from 'history';
import { combineReducers, Reducer } from 'redux';
import apiErrorReducer from './ducks/apiError/apiError';
import appReducer from './ducks/app';
import bootstrapReducer from './ducks/bootstrap';
import modalReducer from './ducks/modal';
import productsReducer from './ducks/products/products';
import recommendedDirectDebitReducer from './ducks/recommendedDirectDebit/recommendedDirectDebit';
import smartMeterBookingReducer from './ducks/smartMeterBooking/smartMeterBooking';
import tariffCostsReducer from './ducks/tariffCosts/tariffCosts';
import monthlyUsageReducer from './ducks/usage/monthly-usage';
import userReducer from './ducks/user';
import { history } from './history';
import evAnytimeReducer from '@/src/redux/ducks/evs/evAnytime';
import evInlifeReducer from '@/src/redux/ducks/evs/evInlife';

const reducers = {
  app: appReducer,
  bootstrap: bootstrapReducer,
  user: userReducer,
  usage: combineReducers({
    monthly: monthlyUsageReducer,
  }),
  router: connectRouter(history) as Reducer<RouterState<LocationState>>,
  apiError: apiErrorReducer,
  products: productsReducer,
  recommendedDirectDebit: recommendedDirectDebitReducer,
  modal: modalReducer,
  smartMeterBooking: smartMeterBookingReducer,
  tariffCosts: tariffCostsReducer,
  evInlife: evInlifeReducer,
  evAnytime: evAnytimeReducer,
};

export default reducers;
