import { PageWithNavigation } from '@/src/components';
import { PageHeader } from '@/src/pages/AnytimeEligibility/components/PageHeader';
import { SkeletonCard } from '@/src/components/SkeletonLoading/SkeletonCard';
import React from 'react';

export const Loading = () => {
  return (
    <PageWithNavigation title="EV Charge Anytime - Eligibility">
      <PageHeader
        title="Check your setup"
        showDescription={false}
        testId="check-eligibility-header"
      />
      <SkeletonCard data-testid="loading-screen" />
    </PageWithNavigation>
  );
};
