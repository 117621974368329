import {
  P,
  Heading4,
  TextLink,
  Margin,
  Card,
  ErrorNotification,
  SecondaryCTAButton,
} from '@ovotech/nebula';
import { AnalyticsClick, AnalyticsView, useAnalytics } from '@ovotech/ui-tools';
import React, { useEffect, memo } from 'react';
import { Link } from 'react-router-dom-v5';
import { AnytimeNotEligible } from '../../components/AnytimeNotEligible';
import { PageHeader } from '../../components/PageHeader';
import { useKaluzaSmartCharger } from '../../hooks/useKaluzaSmartCharger';
import {
  StyledSuccessCard,
  StyledSuccessTextWrapper,
  StyledHeadstartCard,
  StyledHeadstartTextWrapper,
  StyledEVSpinLink,
  LoadingAnimationWrapper,
  StyledP,
  StyledHeadstartImage,
  StyledLogoWrapper,
  StyledFeaturesImageWrapper,
  StyledFeaturesImage,
} from './ChargeAnytimeSignupConfirmation.styled';
import { PageWithNavigation } from '@/src/components';
import {
  CHARGE_ANYTIME_EMAIL_ADDRESS,
  ROUTE_ANYTIME_ELIGIBILITY,
  ROUTE_EV,
} from '@/src/constants/routes';
import { PRODUCT_IDS } from '@/src/constants/products';
import { SmartChargers, State } from '@/src/types/State';
import AnytimeSuccessMobile from '@/src/resources/anytime-success-mobile.png';
import AnytimeSuccess from '@/src/resources/anytime-success.png';
import { ReactComponent as ChargingIcon } from '@/src/resources/charger.svg';
import TempImage from '@/src/resources/headstart-image.png';
import { ReactComponent as OVOIcon } from '@/src/resources/ovo-icon2.svg';
import { useChargeAnytimeBoltonState } from '@/src/pages/AnytimeEligibility/hooks/useChargeAnytimeBoltonState';
import { LoadingStyledCard } from '@/src/pages/AnytimeEligibility/pages/DataSharingAndTCConsent/DataSharingAndTCConsent.styled';
import { useChargeAnytimeContext, EV } from '@monovo/ev-anytime';
import {
  useProductActivationMutation,
  useProductCatalogueQueryV2,
} from '@/src/api';
import { useSelector } from 'react-redux';

const circles: Array<any> = [];
for (let i = 0; i < 12; i++) {
  circles.push(<div className={'circle' + (i + 1) + ' circle'}></div>);
}

const LoadingCard = () => {
  return (
    <>
      <PageHeader title={'Add Charge Anytime'} />
      <AnalyticsView name={EV.SIGNUP.EVS_OVO_CHARGE_ANYTIME_LOADING_VIEWED}>
        <LoadingStyledCard>
          <StyledLogoWrapper>
            <OVOIcon />
            <LoadingAnimationWrapper>{circles}</LoadingAnimationWrapper>
            <ChargingIcon />
          </StyledLogoWrapper>
          <StyledP>
            Hang on a speedy second whilst we get the info we need from your
            charger{' '}
          </StyledP>
        </LoadingStyledCard>
      </AnalyticsView>
    </>
  );
};

const SuccessCard = () => {
  const { dispatch: analyticsDispatch } = useAnalytics();
  /**
   *  We must use redux here. We are navigate to this page outside ChargeAnytime
   *  context and charger selection is lost
   */
  const { smartChargerSelection } = useSelector(
    ({ evAnytime }: State) => evAnytime,
  );

  useEffect(() => {
    analyticsDispatch({
      type: 'view',
      name: 'EVS - EVS OVO Drive Anytime success viewed',
      properties: { smartChargerSelection },
    });
  }, [analyticsDispatch, smartChargerSelection]);

  return (
    <>
      <PageHeader title={'Congratulations!'} showDescription={false} />
      <AnalyticsView
        name={EV.SIGNUP.EVS_OVO_CHARGE_ANYTIME_SUCCESS_VIEWED}
        properties={{ smartChargerSelection }}
      >
        <StyledSuccessCard data-testId="anytime-success-page">
          <StyledSuccessTextWrapper>
            <P>
              You're all set up. We'll smart charge your car using the schedule
              that you set in your charger manufacturer app. If you want to
              change the time your car is ready, you do this using that app.
            </P>
            <P>
              To double-check that Charge Anytime is set up correctly, open your
              charger app and check the tariff page to make sure you're being
              charged the correct amount.
            </P>
            <P>
              To see your charging info and how much you're saving, check{' '}
              <TextLink as={Link} to={ROUTE_EV} opensInNewWindow>
                Your EV hub
              </TextLink>
              . You'll receive the Charge Anytime credits for any time you've
              used smart charging on your next bill.
            </P>
          </StyledSuccessTextWrapper>
          <StyledFeaturesImageWrapper>
            <StyledFeaturesImage
              image={AnytimeSuccess}
              imageMobile={AnytimeSuccessMobile}
            />
          </StyledFeaturesImageWrapper>
        </StyledSuccessCard>
      </AnalyticsView>

      <StyledHeadstartCard data-test-id="headstart-card">
        <StyledHeadstartImage src={TempImage} alt=""></StyledHeadstartImage>
        <StyledHeadstartTextWrapper>
          <Heading4>Get a head start</Heading4>
          <P>
            Race on over to the Your EV hub and check out your charging costs,
            Charge Anytime charging credit, personalised insights, and exclusive
            offers, as well as all the latest EV news.
          </P>
          <div>
            <AnalyticsClick
              name={
                EV.SIGNUP.EVS_OVO_CHARGE_ANYTIME_SUCCESS_SPIN_BUTTON_CLICKED
              }
            >
              <StyledEVSpinLink
                data-event-name={
                  'EVS - EVS OVO Drive Anytime success spin button clicked'
                }
                href={`${ROUTE_EV}`}
                fullWidth="never"
                data-testId="go-to-your-ev"
              >
                Take Your EV for a spin
              </StyledEVSpinLink>
            </AnalyticsClick>
          </div>
        </StyledHeadstartTextWrapper>
      </StyledHeadstartCard>
    </>
  );
};

type ErrorCardProps = {
  type: string;
  title: string;
  description: string;
};

const ErrorCard = (props: ErrorCardProps) => {
  const { type, title, description } = props;
  const errorName =
    type === 'bolton'
      ? EV.SIGNUP.EVS_OVO_CHARGE_ANYTIME_BOLTON_ERROR_VIEWED
      : EV.SIGNUP.EVS_OVO_CHARGE_ANYTIME_KALUZA_ERROR_VIEWED;
  /**
   *  We must use redux here. We are navigate to this page outside ChargeAnytime
   *  context and charger selection is lost
   */
  const { smartChargerSelection } = useSelector(
    ({ evAnytime }: State) => evAnytime,
  );

  const ohmeFlow = smartChargerSelection === 'Ohme';
  return (
    <>
      <Card>
        <AnalyticsView name={errorName} properties={{ smartChargerSelection }}>
          <Margin bottom={6}>
            <ErrorNotification
              id="AnytimeCancelError"
              title={title}
              data-testId="anytime-error-page"
            >
              <P>{description}</P>
            </ErrorNotification>
          </Margin>
        </AnalyticsView>
        {type === 'bolton' && (
          <AnalyticsClick
            name={
              EV.SIGNUP.EVS_OVO_CHARGE_ANYTIME_BOLTON_ERROR_TRY_AGAIN_CLICKED
            }
          >
            <SecondaryCTAButton
              data-event-name={
                'EVS - EVS OVO Drive Anytime Kaluza try again button clicked'
              }
              as={Link}
              to={ROUTE_ANYTIME_ELIGIBILITY.CONSENT}
            >
              Try again
            </SecondaryCTAButton>
          </AnalyticsClick>
        )}
        {type === 'kaluza' && (
          <AnalyticsClick
            name={EV.SIGNUP.EVS_OVO_CHARGE_ANYTIME_KALUZA_ERROR_BACK_CLICKED}
          >
            <SecondaryCTAButton
              data-event-name={
                'EVS - EVS OVO Drive Anytime Kaluza back button clicked'
              }
              as={Link}
              to={
                ohmeFlow
                  ? (process.env.NX_EV_ANYTIME_OHME_IDENTITY_URL as string)
                  : (process.env.NX_EV_ANYTIME_KALUZA_IDENTITY_URL as string)
              }
            >
              Try again
            </SecondaryCTAButton>
          </AnalyticsClick>
        )}
      </Card>
    </>
  );
};

// Indra and Ohme confirmation that confirms Kaluza can communicate with the charger and activates the chargeanytime bolton
export const ChargeAnytimeSignupConfirmation = memo(() => {
  const kaluzaErrorTitle = 'Sorry, there’s a bit of a problem';
  const kaluzaErrorDescription = `We couldn’t connect to your EV charger. Please try again, or email us at ${CHARGE_ANYTIME_EMAIL_ADDRESS} and we’ll be happy to help.`;
  const boltonErrorTitle = 'Sorry, there’s a bit of a problem';
  const boltonErrorDescription = `Please try again. If this doesn’t work, please get in touch with us at ${CHARGE_ANYTIME_EMAIL_ADDRESS}`;
  const {
    connection,
    error: kaluzaError,
    isFetching: isFetchingKaluza,
  } = useKaluzaSmartCharger();
  const { hasChargeAnytime, isFetching: isFetchingChargeAnytime } =
    useChargeAnytimeBoltonState();
  const { hasSmartMeter } = useChargeAnytimeContext();
  /**
   *  We must use redux here. We are navigate to this page outside ChargeAnytime
   *  context and charger selection is lost
   */
  const { smartChargerSelection, isFetching: isFetchingSmartCharger } =
    useSelector(({ evAnytime }: State) => evAnytime);
  const hasSmartCharger = [SmartChargers.Indra, SmartChargers.Ohme].includes(
    smartChargerSelection,
  );
  const { data: productsCatalogue, isFetching: isFetchingCatalogues } =
    useProductCatalogueQueryV2();
  const catalogueBoltons = productsCatalogue?.boltons ?? [];
  const {
    mutate: activateProductMutate,
    isLoading: isActivatingProduct,
    isSuccess: activateProductSucceeded,
    isError: isActiveProductError,
    isIdle: isActivateProductIdle,
  } = useProductActivationMutation();
  const anytimeProduct = catalogueBoltons.find(
    bolton => bolton.productId === PRODUCT_IDS.anytime,
  );

  const isEligible =
    hasSmartMeter &&
    !hasChargeAnytime &&
    hasSmartCharger &&
    connection &&
    !kaluzaError &&
    anytimeProduct !== undefined;

  useEffect(() => {
    if (isEligible && isActivateProductIdle) {
      activateProductMutate({
        product: anytimeProduct,
      });
    }
  }, [
    isEligible,
    isActivateProductIdle,
    anytimeProduct,
    activateProductMutate,
  ]);

  const isLoading =
    isFetchingChargeAnytime ||
    isActivatingProduct ||
    isFetchingSmartCharger ||
    isFetchingKaluza ||
    isFetchingCatalogues;

  if (isLoading) {
    return (
      <PageWithNavigation title="Charge Anytime">
        <LoadingCard />
      </PageWithNavigation>
    );
  }

  if (
    (hasChargeAnytime && connection && !kaluzaError) ||
    (activateProductSucceeded && connection && !kaluzaError)
  ) {
    return (
      <PageWithNavigation title="Charge Anytime">
        <SuccessCard />
      </PageWithNavigation>
    );
  }

  if (isActiveProductError || !anytimeProduct) {
    return (
      <PageWithNavigation title="Charge Anytime">
        <ErrorCard
          type="bolton"
          title={boltonErrorTitle}
          description={boltonErrorDescription}
        />
      </PageWithNavigation>
    );
  }

  if (kaluzaError) {
    return (
      <PageWithNavigation title="Charge Anytime">
        <ErrorCard
          type="kaluza"
          title={kaluzaErrorTitle}
          description={kaluzaErrorDescription}
        />
      </PageWithNavigation>
    );
  }

  if (!connection && !isEligible && hasChargeAnytime === null) {
    return <AnytimeNotEligible />;
  }

  return null;
});
