import { Reason } from '@/src/pages/AnytimeEligibility/pages/Fallout/Fallout.styled';
import { Margin, P, TextLink } from '@ovotech/nebula';
import { ReturnToHomePage } from '@/src/components/ReturnToHomePage/ReturnToHomePage';
import React from 'react';
import { useChargeAnytimeBoltonState } from '@/src/pages/AnytimeEligibility/hooks/useChargeAnytimeBoltonState';
import { VOLVO_APP_LINK, VOLVO_CAR_APP_LINK } from '@monovo/ev-anytime';
import { AlreadyHasChargeAnytimeTitle } from '@/src/pages/AnytimeEligibility/pages/Fallout/components/AlreadyHasChargeAnytime';

const FalloutVolvoInitialised = () => {
  return (
    <Reason data-testid="fallout-volvo-initialized">
      <AlreadyHasChargeAnytimeTitle />
      <P>
        Good news! There’s no need to check your compatibility again. You can
        get Charge Anytime, you just need to complete your set-up.
      </P>
      <p>
        To do this, go to your{' '}
        <TextLink href={VOLVO_APP_LINK} opensInNewWindow>
          Volvo Cars App
        </TextLink>{' '}
        and link your Volvo and OVO accounts. Once you’ve done this, you can
        control all your smart charging in your Volvo Cars app.
      </p>
      <Margin top={2}>
        <ReturnToHomePage />
      </Margin>
    </Reason>
  );
};
const FalloutVolvoActivated = () => {
  return (
    <Reason data-testid="fallout-volvo-active">
      <AlreadyHasChargeAnytimeTitle />
      <P>
        Good news! There’s no need to check your compatibility again. You’re all
        set up.
      </P>
      <p>
        Simply go to your{' '}
        <TextLink href={VOLVO_CAR_APP_LINK} opensInNewWindow>
          Volvo Cars App
        </TextLink>{' '}
        and start smart charging.
      </p>
      <Margin top={2}>
        <ReturnToHomePage />
      </Margin>
    </Reason>
  );
};

export const FalloutVolvo = () => {
  const { payAsYouDriveBoltonActive, payAsYouDriveBoltonInitialised } =
    useChargeAnytimeBoltonState();
  if (payAsYouDriveBoltonActive) {
    return <FalloutVolvoActivated />;
  }
  if (payAsYouDriveBoltonInitialised) {
    return <FalloutVolvoInitialised />;
  }
  return null;
};
