import React from 'react';
import { HomeProfileWidgetResponse } from '@/src/types/Response';
import ReturningCustomer from '@/src/resources/ovo-home.png';
import NewCustomer from '@/src/resources/lifestyle-photography-3.png';
import {
  HomeProfileWidgetContent,
  ResponsiveContent,
} from './HomeProfileWidget.types';

const ReturningCustomerImage = () => (
  <img src={ReturningCustomer} alt="Ovo Home Mobile" width="300px" />
);
const NewCustomerImage = () => (
  <img src={NewCustomer} alt="New Customer" width="300px" />
);

const contentVariants: Record<
  HomeProfileWidgetResponse['variant'],
  ResponsiveContent
> = {
  'new-customer': {
    mobile: {
      heading: 'Tell us about your home',
      body: 'Keep your home profile up-to-date in just a few minutes.',
      cta: 'Get started',
      showCtaChevron: false,
      Image: NewCustomerImage,
    },
    desktop: {
      heading: 'Tell us about your home',
      body: 'Answer 8 quick questions to personalise your insights and offers.',
      cta: 'Get started',
      showCtaChevron: false,
      Image: NewCustomerImage,
    },
  },
  'returning-customer': {
    mobile: {
      heading: 'Check your home profile',
      body: 'Moved recently or made changes? Let us know to get accurate offers and insights.',
      cta: 'View and update',
      showCtaChevron: false,
      Image: ReturningCustomerImage,
    },
    desktop: {
      heading: 'Check your home profile',
      body: 'Moved recently or made changes? Let us know to get accurate offers and insights.',
      cta: 'Update home profile',
      showCtaChevron: true,
      Image: ReturningCustomerImage,
    },
  },
};

export const getHomeProfileWidgetContent = (
  customerVariant: HomeProfileWidgetResponse['variant'],
  responsiveTarget: 'mobile' | 'desktop',
): HomeProfileWidgetContent => {
  return contentVariants[customerVariant][responsiveTarget];
};
