import React from 'react';
import { PageWithNavigation } from '@/src/components';
import { TechnicalGlitch } from '@/src/pages/AnytimeEligibility/components/TechnicalGlitch';
import { PageHeader } from '@/src/pages/AnytimeEligibility/components/PageHeader';

export const TechnicalGlitchPage = ({ header }: { header: string }) => {
  return (
    <PageWithNavigation title={'Technical glitch'}>
      <PageHeader
        title={header}
        showDescription={false}
        testId="check-eligibility-technical-glitch-header"
      />
      <TechnicalGlitch />
    </PageWithNavigation>
  );
};
