import { OptimizelyDecision } from '@/src/utils/optimizely';

export const getIsPTZOffersAvailable = (
  featureFlagVariables: OptimizelyDecision['variables'],
) => {
  const keys = Object.keys(featureFlagVariables);
  const isPTZOffersAvailable: boolean = keys.reduce((acc, key) => {
    const val = featureFlagVariables?.[key];
    // this check is simply to ensure that the value of ff is a boolean
    if (typeof val === 'boolean') {
      return acc || val;
    }
    return acc;
  }, false);

  return isPTZOffersAvailable;
};
