import { useProductsQuery } from '@/src/api';
import { PRODUCT_IDS } from '@/src/constants/products';
import { BoltonInstance, ProductId } from '@/src/types/Products';
import { useAccountContext } from '@ovotech/ui-tools';

export type ChargeAnytimeBoltonState = {
  isFetching: boolean;
  anytimeBolton?: BoltonInstance;
  anytimeBoltonActive: boolean;
  anytimeBoltonActiveFrom?: Date;
  payAsYouDriveBolton?: BoltonInstance;
  payAsYouDriveBoltonActive: boolean;
  hasChargeAnytime: boolean;
  anytimeBoltonInitialized: boolean;
  payAsYouDriveBoltonInitialised: boolean;
  chargeAnytimeBolton?: BoltonInstance;
};

export const chargeAnytimeProducts = [
  PRODUCT_IDS.anytime,
  PRODUCT_IDS.payasyoudrive,
];
export const isChargeAnytimeProduct = (id: ProductId) =>
  chargeAnytimeProducts.includes(id);

export const useChargeAnytimeBoltonState = (): ChargeAnytimeBoltonState => {
  const { accountId } = useAccountContext();
  const products = useProductsQuery(accountId);
  const boltons = products?.data?.boltons || [];

  const anytimeBolton = boltons.find(b => b.productId === PRODUCT_IDS.anytime);
  const anytimeBoltonActiveFrom = anytimeBolton?.activeFrom
    ? new Date(anytimeBolton?.activeFrom)
    : undefined;
  const payAsYouDriveBolton = boltons.find(
    b => b.productId === PRODUCT_IDS.payasyoudrive,
  );

  const anytimeBoltonActive = anytimeBolton?.status === 'Active';
  const payAsYouDriveBoltonActive = payAsYouDriveBolton?.status === 'Active';

  const chargeAnytimeBolton = anytimeBolton || payAsYouDriveBolton;
  const hasChargeAnytime = anytimeBoltonActive || payAsYouDriveBoltonActive;

  return {
    isFetching: products.isFetching,
    anytimeBolton,
    anytimeBoltonActive,
    anytimeBoltonActiveFrom,
    payAsYouDriveBolton,
    payAsYouDriveBoltonActive,
    hasChargeAnytime,
    anytimeBoltonInitialized: anytimeBolton?.status === 'Initialized',
    payAsYouDriveBoltonInitialised:
      payAsYouDriveBolton?.status === 'Initialized',
    chargeAnytimeBolton,
  };
};
