import { ProductId } from '@/src/types/Products';

export const PRODUCT_IDS: {
  [key in
    | 'greenElectricity'
    | 'greenGas'
    | 'greenBundle'
    | 'polarPlus'
    | 'evBundle'
    | 'ovoFoundation'
    | 'greenerEnergy'
    | 'greenerEnergyOneYearFixed'
    | 'greenerElectricity'
    | 'greenerElectricityOneYearFixed'
    | 'ovoDrive'
    | 'boilerCover'
    | 'powerMove'
    | 'anytime'
    | 'payasyoudrive'
    | 'heatingControlTwoYrFix']: ProductId;
} = {
  greenElectricity: 'c4741d4b-5c45-4394-9064-2ab74f962c65',
  greenGas: 'd8d3661e-17a5-4869-ac68-35f4b31c7894',
  greenBundle: 'f8c5dd54-ca93-42b8-bc8f-d6a111639fb5',
  greenerEnergy: 'b30a0bda-68f7-47d5-bcb1-09ded56ed2aa',
  greenerEnergyOneYearFixed: 'b0bdcb8e-0933-4e25-967f-daa8fbc06c77',
  greenerElectricity: '5935a85e-4f40-4ff8-b6fe-ded4632e74f4',
  greenerElectricityOneYearFixed: '3201c3b0-31c5-4d53-81a4-89e3fb4c47f7',
  polarPlus: '1f5c4127-4d0a-461a-8993-bcffaeaf2013',
  evBundle: 'b0b0424c-8f41-11e8-9eb6-529269fb1459',
  ovoFoundation: 'a376b909-383f-472a-82dd-56a7c04a20b7',
  ovoDrive: 'b0b0424c-8f41-11e8-9eb6-529269fb1458',
  anytime: 'e02623e2-512f-4787-a8da-c3719c1a275a',
  payasyoudrive: '3a3f4ab6-6454-49d7-b42f-cb611fe51609',
  powerMove: '373542d9-017c-4f8d-8d7d-9bbf2b36cad7',
  boilerCover: 'XXXX',
  heatingControlTwoYrFix: '5a970c0e-a6ee-4334-8c14-24de47f49239', // Heating Control (2 Year Fixed)
};

export const PROMO_CODES: {
  [key in 'smartThermostatBlackFriday' | 'beyond0524']: string;
} = {
  smartThermostatBlackFriday: 'ST20BF1221',
  beyond0524: 'TADOBEYONDPROMO0524',
};

// @ts-ignore
export const PRODUCT_DISPLAY_NAMES: { [Key in ProductId]: string } = {
  [PRODUCT_IDS.greenElectricity]: 'Green Electricity',
  [PRODUCT_IDS.greenGas]: 'Green Gas',
  [PRODUCT_IDS.polarPlus]: 'Polar plus',
  [PRODUCT_IDS.greenBundle]: 'Green Electricity & Gas',
  [PRODUCT_IDS.greenerEnergy]: 'Greener Energy',
  [PRODUCT_IDS.greenerEnergyOneYearFixed]: 'Greener Energy',
  [PRODUCT_IDS.greenerElectricity]: 'Greener Electricity',
  [PRODUCT_IDS.greenerElectricityOneYearFixed]: 'Greener Electricity',
  [PRODUCT_IDS.evBundle]: 'EV Everywhere',
  [PRODUCT_IDS.ovoDrive]: 'Drive',
  [PRODUCT_IDS.ovoFoundation]: 'OVO Foundation',
  [PRODUCT_IDS.anytime]: 'Charge Anytime',
  [PRODUCT_IDS.payasyoudrive]: 'Charge Anytime',
  [PRODUCT_IDS.boilerCover]: 'OVO Energy boiler cover',
};
