import { useAccountContext } from '@ovotech/ui-tools';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { createProductActivateEndpointV3 } from '@/src/constants/endpoints';
import { activateProductSuccess } from '@/src/redux/ducks/products/products';
import { CatalogueBoltonV2 } from '@/src/types/Products';
import { apiService } from '@/src/services/api-service';

type QueryCacheKey = `product-activation-${string}-v2`;

export type ProductActivationMutationProps = {
  product: CatalogueBoltonV2;
  promo?: string;
};

export const useProductActivationMutation = (
  onSuccess?: (response: unknown) => void,
  onError?: (error: any) => void,
) => {
  const { accountId } = useAccountContext();

  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    ({ promo, product }: ProductActivationMutationProps) =>
      apiService.post<unknown>({
        url: createProductActivateEndpointV3(accountId ?? ''),
        body: {
          source: 'MyOVO',
          products: [
            {
              versionId: product.versionId,
              ...(promo ? { promoCredit: promo } : {}),
            },
          ],
        },
      }),
    {
      mutationKey: `product-activation-${accountId}-v2` as QueryCacheKey,
      onSuccess: (response, { product: { productId, displayName } }) => {
        dispatch(activateProductSuccess(productId, displayName)); // TODO: remove when we can fully transition to react-query
        queryClient.invalidateQueries({
          queryKey: [`orion-product-catalogue-${accountId}`],
        });
        queryClient.invalidateQueries({
          queryKey: ['orion-product-catalogue-v2', accountId],
        });
        if (onSuccess) {
          onSuccess(response);
        }
      },
      onError: error => {
        if (onError) {
          onError(error);
        }
      },
    },
  );
};
