import { useProfileQueryKapi } from '@/src/api/kapi/use-profile-query';
import { PageWithNavigationAndSectionPadding } from '@/src/components';
import { State } from '@/src/types/State';
import { Card, DataTable } from '@ovotech/nebula';
import { useAccountContext } from '@ovotech/ui-tools';
import React from 'react';
import { useSelector } from 'react-redux';
import { useChargeAnytimeBoltonState } from '../AnytimeEligibility/hooks/useChargeAnytimeBoltonState';

export const BatteryBoostDataSpike = () => {
  const { accountId } = useAccountContext();
  const profile = useProfileQueryKapi();

  const isPayG = useSelector(
    (state: State) =>
      state.app.accounts.find(acc => acc.accountId === accountId)?.isPayg,
  );

  const { isOvoElectricSupplier, isElectricMeterSmart } = useSelector(
    (state: State) => ({
      isOvoElectricSupplier: !!state.app.elecSupply,
      isElectricMeterSmart: state.app.elecSupply?.supplyPointInfo?.isSmart,
    }),
  );
  const { hasChargeAnytime } = useChargeAnytimeBoltonState();
  const booleanToYesOrNo = (value: boolean) => (value ? 'yes' : 'no');

  return (
    <PageWithNavigationAndSectionPadding title="Data spike">
      <Card>
        <DataTable
          columnHeadings={['Field', 'Value']}
          items={[
            ['Ovo id', accountId],
            ['Giv ID', 'Customer input'],
            [
              'Postal code(will get the lat/long based on this)',
              profile.data?.customer_nextV1.contactAddress?.postalAddress
                .postalCode,
            ],
            ['First name', profile.data?.customer_nextV1.givenName],
            ['Last name', profile.data?.customer_nextV1.familyName],
            ['Email', profile.data?.customer_nextV1.contactEmail],
            [
              'Phone number',
              profile.data?.customer_nextV1.contactPhoneNumbers[0],
            ],
            ['Have battery', 'Customer input'],
            ['Battery installed by OVO', 'Customer input'],
            ['Ovo supply electricity', booleanToYesOrNo(isOvoElectricSupplier)],
            ['Have solar panels', 'Customer input'],
            ['SEG by Ovo', 'Customer input'],
            ['Have smart meter', booleanToYesOrNo(!!isElectricMeterSmart)],
            ['Tariff is pay monthly', booleanToYesOrNo(!isPayG)],
            ['Have charge anytime', booleanToYesOrNo(hasChargeAnytime)],
            ['Have Heat Pump Plus', 'Customer input'],
          ]}
        />
      </Card>
    </PageWithNavigationAndSectionPadding>
  );
};
