import { Margin } from '@ovotech/nebula';
import React, {
  Component,
  KeyboardEvent,
  PropsWithChildren,
  ReactElement,
} from 'react';

import {
  StyledAccordionUl,
  StyledButton,
  StyledButtonContent,
  StyledDownArrow,
  StyledDropdownUl,
  StyledNavArrow,
  StyledNavDropdown,
  StyledNavLabel,
  StyledUpArrow,
} from './NavDropdown.styled';

type Props = PropsWithChildren<{
  label: string;
  // Must be a <li />
  listItems: Array<ReactElement<any>>;
  dropdownAlignment?: 'left' | 'right';
  accordion?: boolean;
  hasLightBackground?: boolean;
  showBadge?: boolean;
  badge?: ReactElement;
}>;

type State = {
  isOpen: boolean;
};

class NavDropdown extends Component<Props, State> {
  static dropdownAlignment = 'left';

  state = {
    isOpen: false,
  };

  navDropdown: HTMLDivElement | null = null;
  navButton: HTMLButtonElement | null = null;

  componentDidMount() {
    window.addEventListener('click', this.closeMenuOnOutsideClick);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.closeMenuOnOutsideClick);
  }

  setNavDropdown = (ref: HTMLDivElement | null) => {
    this.navDropdown = ref;
  };
  setNavButton = (ref: HTMLButtonElement | null) => {
    this.navButton = ref;
  };

  closeMenu = () => {
    this.setState({ isOpen: false });
  };

  closeIfEscape = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      this.closeMenu();
      if (this.navButton) {
        this.navButton.focus();
      }
    }
  };

  toggleMenu = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  closeMenuOnOutsideClick = (event: Event) => {
    if (this.navDropdown && !this.navDropdown.contains(event.target as Node)) {
      this.closeMenu();
    }
  };

  renderDropdown(hidden: boolean) {
    return (
      <StyledDropdownUl
        aria-label="sub menu"
        data-testid="dropdown-menu"
        dropdownAlignment={this.props.dropdownAlignment!}
        isHidden={hidden}
      >
        {this.props.listItems.map((listItem, index) =>
          React.cloneElement(listItem, {
            key: index,
            onClick: this.closeMenu,
          }),
        )}
      </StyledDropdownUl>
    );
  }

  renderAccordion(hidden: boolean) {
    return (
      <Margin left={4}>
        <StyledAccordionUl
          aria-label="sub menu"
          data-testid="dropdown-menu"
          isHidden={hidden}
        >
          {this.props.listItems.map((listItem, index) =>
            React.cloneElement(listItem, {
              key: index,
              onClick: this.closeMenu,
            }),
          )}
        </StyledAccordionUl>
      </Margin>
    );
  }

  render() {
    const hidden = this.state.isOpen ? false : true;
    return (
      <StyledNavDropdown
        ref={this.setNavDropdown}
        onKeyDown={this.closeIfEscape}
      >
        <StyledButton
          ref={this.setNavButton}
          onClick={this.toggleMenu}
          aria-expanded={this.state.isOpen}
          data-testid={`dropdown-button-${this.props.label.toLowerCase()}`}
        >
          <StyledButtonContent>
            {this.props.showBadge && this.props.badge}
            <StyledNavLabel
              hasLightBackground={this.props.hasLightBackground}
              className="label"
            >
              {this.props.label}
            </StyledNavLabel>
            <StyledNavArrow aria-hidden="true">
              {this.state.isOpen ? (
                <StyledUpArrow
                  hasLightBackground={this.props.hasLightBackground}
                />
              ) : (
                <StyledDownArrow
                  hasLightBackground={this.props.hasLightBackground}
                />
              )}
            </StyledNavArrow>
          </StyledButtonContent>
        </StyledButton>
        {this.props.accordion
          ? this.renderAccordion(hidden)
          : this.renderDropdown(hidden)}
      </StyledNavDropdown>
    );
  }
}

export default NavDropdown;
