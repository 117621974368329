import { useChargeAnytimeContext } from '../lib/charge-anytime-context';
import { useChargeAnytimeDevices } from './useChargeAnytimeDevices';
import { generateVehicleSelections } from '../lib/anytime-vehicles';

export const useVehicleOptions = () => {
  const { chargeAnytimeApi } = useChargeAnytimeContext();
  const { devicesEndpointEnabled } = chargeAnytimeApi.config;
  const { data, isLoading, isError } = useChargeAnytimeDevices('vehicles');
  return {
    ...generateVehicleSelections(data?.devices ?? [], devicesEndpointEnabled),
    isLoading: devicesEndpointEnabled && isLoading,
    isError,
  };
};
