import {
  Card,
  ErrorNotification,
  P,
  PrimaryCTAButton,
  TextLink,
} from '@ovotech/nebula';
import { useAccountContext, useCustomerContext } from '@ovotech/ui-tools';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useCreateCardPaymentSessionMutation } from '@/src/api';
import {
  LoadingSpinner,
  PageWithNavigationAndSectionPadding,
} from '@/src/components';
import { CreateCardPaymentSessionResponse } from '@/src/types/Response';
import AnalyticsService from '@/src/services/analyticsService';
import { useProfileQueryKapi } from '@/src/api/kapi/use-profile-query';
import { BalanceType, useBalance } from '@/src/pages/Payments/hooks/useBalance';
import { PostalAddress } from '@ovotech/energy-cx/dist/shared/api/kapi/v1/__generated__/kapi';

const getAddress = (postalAddress?: PostalAddress) => {
  return {
    CustomerAddressLine1: postalAddress?.line1 ?? undefined,
    CustomerAddressLine2: postalAddress?.line2 ?? undefined,
    CustomerAddressCity: postalAddress?.town ?? undefined,
    CustomerAddressPostCode: postalAddress?.postalCode ?? undefined,
  };
};

const getFormattedAccountBalance = (
  closingBalance: string | undefined,
): string => {
  if (!closingBalance) return '';
  return Number(closingBalance).toFixed(2).toString();
};

export const CardPaymentSession = () => {
  const { customerId } = useCustomerContext();
  const { accountId } = useAccountContext();
  const manualRedirectTimer = useRef<NodeJS.Timeout>();
  const [manualRedirectUrl, setManualRedirectUrl] = useState<null | string>(
    null,
  );

  const { data: profileData, isError: isProfileError } = useProfileQueryKapi();
  const {
    mutate,
    isError: isCardPaymentSessionError,
    isLoading: isCardPaymentSessionLoading,
  } = useCreateCardPaymentSessionMutation(accountId);

  const {
    isLoading: isUseBalanceLoading,
    balanceType,
    liveBalance,
    collectableBalance,
    isError: isUseBalanceError,
  } = useBalance();

  const formattedAccountBalance = getFormattedAccountBalance(
    balanceType === BalanceType.CollectableBalance && collectableBalance
      ? collectableBalance
      : liveBalance,
  );

  const isLoading = isCardPaymentSessionLoading || isUseBalanceLoading;

  const hasError =
    isCardPaymentSessionError || isProfileError || isUseBalanceError;

  const onMutationSuccess = useCallback(
    (data: CreateCardPaymentSessionResponse | null) => {
      if (data?.SessionUrl) {
        manualRedirectTimer.current = setTimeout(
          () => setManualRedirectUrl(data?.SessionUrl),
          5000,
        );
        window.location.href = data?.SessionUrl;
      }
    },
    [],
  );

  useEffect(
    () => () => {
      if (manualRedirectTimer.current)
        clearTimeout(manualRedirectTimer.current);
    },
    [],
  );

  const loadMakePaymentUrl = useCallback(() => {
    if (
      accountId &&
      customerId &&
      formattedAccountBalance &&
      profileData?.customer_nextV1.contactAddress?.postalAddress &&
      !isUseBalanceLoading
    ) {
      mutate(
        {
          AccountId: accountId,
          AccountBalance: formattedAccountBalance,
          CustomerAddressCountry: 'GB',
          CustomerId: customerId,
          ...getAddress(
            profileData?.customer_nextV1.contactAddress.postalAddress,
          ),
        },
        {
          onSuccess: onMutationSuccess,
        },
      );
    } else {
      AnalyticsService.dispatch(
        'Card Payment Session - Generating session url - Mutation variables not defined',
        {
          hasAccountId: !!accountId,
          hasBalance: !!formattedAccountBalance,
          hasCustomerId: !!customerId,
          hasAddress:
            !!profileData?.customer_nextV1.contactAddress?.postalAddress,
        },
      );
    }
  }, [
    accountId,
    customerId,
    formattedAccountBalance,
    profileData?.customer_nextV1?.contactAddress?.postalAddress,
    isUseBalanceLoading,
    mutate,
    onMutationSuccess,
  ]);

  useEffect(() => {
    loadMakePaymentUrl();
  }, [loadMakePaymentUrl]);

  return (
    <PageWithNavigationAndSectionPadding title="Card payment">
      <Card>
        {hasError ? (
          <ErrorNotification
            id="address-api-error"
            title="Sorry, there's a bit of a problem"
          >
            <P>Please try again.</P>
            <PrimaryCTAButton
              type="button"
              onClick={loadMakePaymentUrl}
              disabled={isLoading}
              data-testid="retry-create-card-payment-session"
            >
              Retry
            </PrimaryCTAButton>
          </ErrorNotification>
        ) : (
          <>
            <LoadingSpinner text="You're being redirected to a secure website to enter your card details." />
            {manualRedirectUrl && (
              <P style={{ textAlign: 'center' }}>
                <TextLink
                  data-testid="manualRedirectLink"
                  href={manualRedirectUrl}
                >
                  Click here
                </TextLink>{' '}
                if you have not been redirected automatically.
              </P>
            )}
          </>
        )}
      </Card>
    </PageWithNavigationAndSectionPadding>
  );
};
