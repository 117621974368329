export const hubTestIDs = {
  carbonComparisonHeader: 'carbon-comparison-header',
  carbonComparison: 'carbon-comparison',
  breakdownWidgetV2: 'breakdown-widgetV2',
  breakdownYourHome: 'breakdown-your-home',
  breakdownSimilarHome: 'breakdown-similar-home',
  breakdownCTA: 'breakdown-cta',
  breakdownWidgetHeading: 'breakdown-widget-heading',
  breakdownWidgetSubHeading: 'breakdown-widget-sub-heading',
  breakdownWidgetElecStats: 'breakdown-widget-elec-stats',
  carbonIntensityWidget: 'carbon-intensity-widget',
  nonSmartTips: 'non-smart-tips',
  halfHourlyOptIn: 'half-hourly-opt-in',
  halfHourlyOptInButton: 'half-hourly-opt-in-button',
  halfHourlyOptInDefaultText: 'half-hourly-opt-in-default',
  halfHourlyOptInSuccessText: 'half-hourly-opt-in-success',
  halfHourlyOptInFailureText: 'half-hourly-opt-in-failure',
};
