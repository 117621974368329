import styled from 'styled-components';

export const StyledApp = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
`;

export const StyledRouteContent = styled.main`
  flex-grow: 1;
`;

export const DisplayFlex = styled.div`
  display: flex;
`;
export const NestedDisplayFlex = styled.div`
  display: flex;

  > *,
  [data-testid='kapi-switch'] > * {
    flex: 1;
    display: flex;
  }
`;

export const FlexChild = styled.div`
  flex: 1;
`;

export const StyledLoadingBackground = styled.div`
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  opacity: 1;
`;
