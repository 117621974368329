import React, { useEffect } from 'react';
import { Link } from 'react-router-dom-v5';

import {
  Heading1,
  Heading4,
  P,
  Small,
  Strong,
  Margin,
  SecondaryCTAButton,
  Stack,
} from '@ovotech/nebula';

import theme from '@/src/core/theme';
import { GridContainer } from '@/src/pages/EnergyInsights/components';
import { HubBreakdownWidgetDataV2 } from '@/src/pages/EnergyInsights/types';
import { ElectricityIcon, GasIcon } from '@/src/components/icons';
import { ROUTE_ENERGY_INSIGHTS } from '@/src/constants/routes';
import AnalyticsService from '@/src/services/analyticsService';
import { ENERGY_INSIGHTS } from '@/src/constants/analytics';

import { hubTestIDs } from '../../testIDs';
import { WebSmartMeterIcon } from './SmartMeterIcon';
import * as S from './BreakdownWidgetV2.styled';

export const BreakdownWidgetV2 = ({
  dateHeading,
  totalCost,
  totalKwh,
  elecStats,
  gasStats,
}: HubBreakdownWidgetDataV2) => {
  useEffect(() => {
    AnalyticsService.dispatch(ENERGY_INSIGHTS.VIEWED_HUB_BREAKDOWN_WIDGET);
  }, []);

  return (
    <GridContainer
      spanSmall={12}
      spanMedium={6}
      spanLarge={6}
      includeRow={false}
    >
      <Heading4 style={{ margin: 0 }}>Your weekly energy use</Heading4>
      <P style={{ margin: `0 0 ${theme.space[2]} 0` }}>
        Learn where your energy goes and get some handy tips on cutting carbon
      </P>
      <S.WidgetCard data-testid={hubTestIDs.breakdownWidgetV2}>
        <WebSmartMeterIcon />
        <Stack spaceBetween={10}>
          {dateHeading}
          <Margin />
          <div>
            <S.EstimatedBadge>Estimated</S.EstimatedBadge>
            <Heading1
              data-testid={hubTestIDs.breakdownWidgetHeading}
              style={{ margin: 0 }}
            >
              {totalCost || totalKwh}
            </Heading1>
            {!!totalCost && (
              <Small
                data-testid={hubTestIDs.breakdownWidgetSubHeading}
                style={{ color: `${theme.colors.roundalDark}` }}
              >
                ({totalKwh})
              </Small>
            )}
          </div>
          <Margin />
          <Strong>
            {!!elecStats && (
              <S.IconWithText data-testid={hubTestIDs.breakdownWidgetElecStats}>
                <ElectricityIcon
                  color={theme.colors.fuelElec}
                  aria-hidden="true"
                />
                <Margin right={2} />
                {elecStats}
              </S.IconWithText>
            )}
            {!!gasStats && (
              <S.IconWithText>
                <GasIcon color={theme.colors.fuelGas} aria-hidden="true" />
                <Margin right={2} />
                {gasStats}
              </S.IconWithText>
            )}
          </Strong>
          <Margin top={5} />
        </Stack>
        <SecondaryCTAButton
          as={Link}
          iconRight="chevron-right"
          to={ROUTE_ENERGY_INSIGHTS.BILL_BREAKDOWN}
          data-testid={hubTestIDs.breakdownCTA}
          onClick={() => {
            AnalyticsService.dispatch(
              ENERGY_INSIGHTS.BUTTON_CLICK_HUB_NAVIGATE_TO_BREAKDOWN,
            );
          }}
        >
          See all categories and tips
        </SecondaryCTAButton>
      </S.WidgetCard>
    </GridContainer>
  );
};
