import React from 'react';
import styled from 'styled-components';
import SmartMeterImage from '@/src/resources/smart-meter-no-background.png';

const ImageBackground = styled.div`
  position: absolute;
  top: -25px;
  right: -22px;
  width: 150px;
  height: 150px;
  transform: rotate(-15deg);
  background: var(--colour-brand-ovo-core-colour-brand-leaf, #16b639);
`;

const ImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: rotate(15deg);
  display: flex;
  align-items: center;
  padding: 25px 0 0 25px;
`;

const Image = styled.img`
  max-width: 65%;
  height: auto;
`;

export const WebSmartMeterIcon = () => {
  return (
    <ImageBackground>
      <ImageWrapper>
        <Image src={SmartMeterImage} alt="smart meter" />
      </ImageWrapper>
    </ImageBackground>
  );
};
