export const VOLVO_CAR_APP_LINK = 'https://www.volvocars.com/l/vca/charge';
export const VOLVO_APP_LINK =
  'https://www.volvocars.com/l/vca/smartcharging/accountlinking';
export const VOLVO_PROMO_FORM = 'https://survey.zohopublic.eu/zs/mJDXET';
export const VWG_PROMO_FORM = 'https://survey.zohopublic.eu/zs/5LDXcT';
export const VWG_PROMO_HEADING =
  'Enter your Volkswagen Group (VWG) order number';
export const VWG_PROMO_COPY =
  'Eligible EVs are Audi, Cupra, Skoda and Volkswagen bought since 1 July 2024';
export const COMPATIBLE_EVS_AND_CHARGERS_LINK =
  'https://www.ovoenergy.com/electric-cars/charge-anytime-cars-and-chargers#chargers';
