import { P, Card, Radio, RadioField, PrimaryCTAButton } from '@ovotech/nebula';
import { useAnalytics } from '@ovotech/ui-tools';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom-v5';
import styled from 'styled-components';
import { SectionHeader } from '../../components/SectionHeader';
import { ROUTE_ANYTIME_ELIGIBILITY, ROUTE_EV } from '@/src/constants/routes';
import { hasSMETS2Meters } from '@/src/pages/Renewal/selectors';
import { ReactComponent as SmartChargerIcon } from '@/src/resources/smart-charger.svg';
import { useFeature } from '@/src/utils/optimizely';
import { ALLOW_ANYTIME_SIGNUP } from '@/src/constants/feature-flags';
import {
  useChargeAnytimeContext,
  EV,
  useChargerOptions,
} from '@monovo/ev-anytime';
import { useChargeAnytimeBoltonState } from '../../hooks/useChargeAnytimeBoltonState';
import { PageHeader } from '@/src/pages/AnytimeEligibility/components/PageHeader';
import { PageWithNavigation } from '@/src/components';
import { JourneyUnavailable } from '@/src/pages/AnytimeEligibility/components/JourneyUnavailable';
import { Loading } from '@/src/pages/AnytimeEligibility/components/Loading';
import { TechnicalGlitchPage } from '@/src/pages/AnytimeEligibility/pages/TechnicalGlitchPage/TechnicalGlitchPage';

const CardContentWrapper = styled.div`
  max-width: 640px;
`;

const SectionContainer = styled.div`
  margin-bottom: 25px;
`;

const StyledP = styled(P)`
  color: #2f3749;
`;

export const SmartChargerQuestions = () => {
  const history = useHistory();
  const { anytimeBoltonActive } = useChargeAnytimeBoltonState();
  const { dispatch: analyticsDispatch } = useAnalytics();
  const [allowAnytimeSignUp] = useFeature(ALLOW_ANYTIME_SIGNUP);
  const { chargers, isLoading, isError } = useChargerOptions();
  const firstCharger = chargers[0];

  const { smartCharger, setSmartCharger, hasSmartMeter } =
    useChargeAnytimeContext();

  const hasSMETS2SmartMeter = useSelector(hasSMETS2Meters);

  if (anytimeBoltonActive) {
    history.push(ROUTE_EV);
  }

  useEffect(() => {
    if (!smartCharger && firstCharger?.value && !isLoading) {
      setSmartCharger(firstCharger.value);
    }
  }, [setSmartCharger, smartCharger, firstCharger, isLoading]);

  useEffect(() => {
    analyticsDispatch({
      type: 'view',
      name: EV.ELIGIBILITY
        .EVS_OVO_CHARGE_ANYTIME_ELIGIBILITY_SMART_CHARGER_VIEWED,
      properties: {
        hasNoSMETS2SmartMeter: !hasSMETS2SmartMeter,
        hasNoSmartMeter: !hasSmartMeter,
      },
    });
    // `analyticsDispatch` method is not memoized and it causes useless calls.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSmartMeter, hasSMETS2SmartMeter]);

  useEffect(() => {
    analyticsDispatch({
      type: 'view',
      name: 'EVS - EVS OVO Drive Anytime Smartcharger Eligibility Viewed',
      properties: {
        hasNoSMETS2SmartMeter: !hasSMETS2SmartMeter,
        hasNoSmartMeter: !hasSmartMeter,
      },
    });
    // `analyticsDispatch` method is not memoized and it causes useless calls.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSmartMeter, hasSMETS2SmartMeter]);

  const goToNextPage = () => {
    analyticsDispatch({
      type: 'click',
      name: EV.ELIGIBILITY
        .EVS_OVO_CHARGE_ANYTIME_ELIGIBILITY_SMART_CHARGER_SUBMIT_CLICKED,
      properties: {
        chargerName: smartCharger,
      },
    });

    analyticsDispatch({
      type: 'click',
      name: 'EVS - EVS OVO Charge Anytime Charger Select submit clicked',
      properties: {
        chargerName: smartCharger,
      },
    });

    history.push(ROUTE_ANYTIME_ELIGIBILITY.VEHICLE_AND_HOME_SETUP);
  };

  if (isError) {
    return <TechnicalGlitchPage header={'Check your setup'} />;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <PageWithNavigation title="EV Charge Anytime - Eligibility">
      {allowAnytimeSignUp ? (
        <>
          <PageHeader
            title="Check your setup"
            showDescription={false}
            testId="check-eligibility-header"
          />
          <Card data-testid="eligibility-questions-body">
            <CardContentWrapper>
              <SectionContainer>
                <SectionHeader Icon={SmartChargerIcon}>
                  What EV charger do you have at home?
                </SectionHeader>
                <StyledP>
                  If you don’t have one of the chargers below, or don’t have a
                  charger, don’t worry: you can still connect to Charge Anytime,
                  given the rest of your set-up is compatible.
                  <br />
                  You can find the charger model name on the sticker on the side
                  of the charger.
                </StyledP>

                <RadioField label="" name="selected">
                  {chargers.map(charger => (
                    <div
                      key={charger.value}
                      data-testid={`radio-button-${charger.value}`}
                    >
                      <Radio
                        id={charger.value}
                        value={charger.value}
                        label={charger.text}
                        checked={smartCharger === charger.value}
                        onChange={() => setSmartCharger(charger.value)}
                      />
                    </div>
                  ))}
                </RadioField>
              </SectionContainer>
              <PrimaryCTAButton
                data-testid="next-button"
                onClick={goToNextPage}
              >
                Next: tell us about your EV and home
              </PrimaryCTAButton>
            </CardContentWrapper>
          </Card>
        </>
      ) : (
        <JourneyUnavailable />
      )}
    </PageWithNavigation>
  );
};
