import styled from 'styled-components';
import React, { forwardRef } from 'react';
import { AnalyticsClick } from '@ovotech/ui-tools';
import { Icon, P } from '@ovotech/nebula';
import { INTERNAL_EVENT_FLAG } from '@ovotech/energy-cx';
import { InternalLink } from '@/src/components/Navigation/InternalLink';

type Props = JSX.IntrinsicElements['a'];

const StyledTextLink = styled.a<Props>(
  ({ theme: { space } }) => `
    display: inline-flex;
    line-height: 1.2;
    gap: ${space[2]};
  `,
);

export const StyledBreadCrumb = forwardRef<HTMLAnchorElement, Props>(
  ({ children, href = '', ...rest }: Props, ref) => (
    <P>
      <AnalyticsClick
        name="Breadcrumb"
        properties={{ [INTERNAL_EVENT_FLAG]: true }}
      >
        <StyledTextLink href={href} as={InternalLink} {...rest} ref={ref}>
          <Icon name="chevron-left" size="1.2em" />
          {children}
        </StyledTextLink>
      </AnalyticsClick>
    </P>
  ),
);
