import { useState, useEffect } from 'react';
import { breakpointVals } from '../mediaQuery';

interface WindowDimensions {
  width: number;
  height: number;
}

interface ResponsiveDimensions extends WindowDimensions {
  isMobile: boolean;
  isDesktop: boolean;
  isMobileOrTablet: boolean;
}

function getWindowDimensions(): WindowDimensions {
  const { innerWidth: width, innerHeight: height } = window;

  return { width, height };
}

function debounce<T extends (...args: unknown[]) => void>(
  func: T,
  delay: number,
): (...args: Parameters<T>) => void {
  let timeout: ReturnType<typeof setTimeout>;

  return function (...args: Parameters<T>) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), delay);
  };
}

export default function useWindowDimensions({
  debounceDelay = 100,
} = {}): ResponsiveDimensions {
  const [windowDimensions, setWindowDimensions] =
    useState<WindowDimensions>(getWindowDimensions);

  const isMobile = windowDimensions.width < breakpointVals.small;
  const isDesktop = windowDimensions.width > breakpointVals.navigation;
  const isMobileOrTablet = windowDimensions.width < breakpointVals.medium;

  const handleResize = debounce(() => {
    setWindowDimensions(getWindowDimensions());
  }, debounceDelay);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  return {
    ...windowDimensions,
    isMobile,
    isDesktop,
    isMobileOrTablet,
  };
}
