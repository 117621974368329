import { AnalyticsClick } from '@ovotech/ui-tools';
import React from 'react';
import { StyledGreenerEnergyListItem } from './PlanLinks.styled';
import NavDropdown from './Links/NavDropdown';
import NavLink from './Links/NavLink';
import { MENU } from '@/src/constants/analytics';

import {
  MENU_GREENER_ENERGY_LINK,
  MENU_GREENER_ELECTRICITY_LINK,
  MENU_PLAN_DETAILS_LINK,
  MENU_PLAN_DROPDOWN,
} from '@/src/constants/analytics-subjects';
import { SHOW_GREENER_ELECTRICITY } from '@/src/constants/feature-flags';
import {
  ROUTE_GREENER_ELECTRICITY,
  ROUTE_GREENER_ENERGY,
  ROUTE_PLAN,
} from '@/src/constants/routes';
import { useFeature } from '@/src/utils/optimizely';
import { useGreenerEnergySignup } from '@/src/pages/GreenerEnergy/GreenerEnergySignup/hooks/useGreenerEnergySignup';
import { useGreenerElectricitySignup } from '@/src/pages/GreenerElectricity/GreenerElectricitySignup/hooks/useGreenerElectricitySignup';

type Props = {
  closeMobileMenu?: () => void;
  path: string;
  type: 'accordion' | 'dropdown';
};

const planLinks = ({
  path,
  closeMobileMenu,
  showGreenerEnergy,
  showGreenerElectricity,
}: Pick<Props, 'path' | 'closeMobileMenu'> & {
  showGreenerEnergy?: boolean;
  showGreenerElectricity?: boolean;
}) => {
  const links = [
    <AnalyticsClick
      name={MENU_PLAN_DETAILS_LINK}
      properties={{ legacyName: MENU.PLAN_DETAILS }}
      key="Plan details"
    >
      <li>
        <NavLink
          path={path}
          route={ROUTE_PLAN}
          label="Plan details"
          onClick={closeMobileMenu}
        />
      </li>
    </AnalyticsClick>,
  ];

  if (showGreenerEnergy) {
    links.push(
      <AnalyticsClick key="Greener Energy" name={MENU_GREENER_ENERGY_LINK}>
        <StyledGreenerEnergyListItem>
          <NavLink
            path={path}
            route={ROUTE_GREENER_ENERGY}
            label="Greener Energy"
            data-testid="greener-energy-link"
            onClick={closeMobileMenu}
          />
        </StyledGreenerEnergyListItem>
      </AnalyticsClick>,
    );
  }

  if (showGreenerElectricity) {
    links.push(
      <AnalyticsClick
        key="Greener Electricity"
        name={MENU_GREENER_ELECTRICITY_LINK}
      >
        <StyledGreenerEnergyListItem>
          <NavLink
            path={path}
            route={ROUTE_GREENER_ELECTRICITY}
            label="Greener Electricity"
            onClick={closeMobileMenu}
          />
        </StyledGreenerEnergyListItem>
      </AnalyticsClick>,
    );
  }

  return links;
};

export const PlanLinksWrapper = ({ closeMobileMenu, path, type }: Props) => {
  const [showGreenerElectricity] = useFeature(SHOW_GREENER_ELECTRICITY);

  const { hasAnyGreenerEnergyAddon: hasGreenerEnergy } =
    useGreenerEnergySignup();

  const { hasAnyGreenerElectricityAddon: hasGreenerElectricity } =
    useGreenerElectricitySignup();

  const closeMobileMenuClick = () => {
    closeMobileMenu && closeMobileMenu();
  };

  // Don't show Greener Electricity if the has Greener Energy
  // Otherwise show if the feature flag is on, or the user already has it
  const includeGreenerElectricityLink =
    !hasGreenerEnergy && (showGreenerElectricity || hasGreenerElectricity);

  if (hasGreenerEnergy || includeGreenerElectricityLink) {
    return (
      <>
        <AnalyticsClick
          name={MENU_PLAN_DROPDOWN}
          properties={{
            legacyName: MENU.OPEN_PLAN_DROPDOWN,
          }}
        >
          <li>
            <NavDropdown
              label="Plan"
              listItems={planLinks({
                path,
                closeMobileMenu: closeMobileMenuClick,
                showGreenerEnergy: hasGreenerEnergy,
                showGreenerElectricity: includeGreenerElectricityLink,
              })}
              accordion={type === 'accordion'}
            />
          </li>
        </AnalyticsClick>
      </>
    );
  }

  return (
    <NavLink
      path={path}
      route={ROUTE_PLAN}
      label="Plan"
      onClick={closeMobileMenuClick}
    />
  );
};
