import { useQuery } from 'react-query';
import { useChargeAnytimeContext } from '../lib/charge-anytime-context';
import { DeviceType, DeviceResponseType } from '../lib/types';

export const useChargeAnytimeDevices = <T extends DeviceType>(
  deviceType: T
) => {
  const { chargeAnytimeApi } = useChargeAnytimeContext();
  return useQuery<DeviceResponseType<T>, unknown>({
    enabled: chargeAnytimeApi.config.devicesEndpointEnabled,
    queryKey: ['charge-anytime-devices', deviceType],
    queryFn: () => {
      return chargeAnytimeApi.fetch(`/v1/devices/${deviceType}`);
    },
  });
};
