import styled from 'styled-components';
import { Badge, theme, Card } from '@ovotech/nebula';

export const WidgetCard = styled(Card)`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const EstimatedBadge = styled(Badge)`
  width: fit-content;
  color: ${theme.colors.border};
`;

export const IconWithText = styled.div`
  display: flex;
`;
