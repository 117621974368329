import { mapKapiToLegacy } from '../utils/mapKapiBootstrap';
import { useQuery } from '@apollo/client';
import { graphql } from './__generated__';

const BOOTSTRAP_QUERY = graphql(/* kapi-codegen-graphql */ `
  query Bootstrap($customerId: ID!) {
    customer_nextV1(id: $customerId) {
      id
      customerAccountRelationships {
        edges {
          node {
            account {
              accountNo
              id
              accountSupplyPoints {
                ...AccountSupplyPoint
              }
            }
          }
        }
      }
    }
  }

  fragment AccountSupplyPoint on AccountSupplyPoint {
    startDate
    supplyPoint {
      sprn
      fuelType
      meterTechnicalDetails {
        meterSerialNumber
        mode
        type
        status
      }
      address {
        addressLines
        postCode
      }
    }
  }
`);

export const useBootstrapQuery = (customerId: string | null | undefined) => {
  const { data, loading, error } = useQuery(BOOTSTRAP_QUERY, {
    variables: { customerId: customerId ?? '' },
    skip: !customerId,
  });

  return {
    loading,
    error,
    data: data ? mapKapiToLegacy(data) : undefined,
  };
};
