import { BootstrapAccount } from '@ovotech/energy-cx/dist/shared/api/legacy-queries/bootstrap/types';

// export only for testing
export const getEarliestSupplyStartDate = (
  account: BootstrapAccount,
): string | null => {
  let earliestStartDate: string | null = null;

  for (const supply of account.supplies) {
    if (!earliestStartDate || supply?.start < earliestStartDate) {
      earliestStartDate = supply?.start; // this in theory is not optional, but better be safe
    }
  }

  return earliestStartDate;
};

export const sortByEarliestStartDate = (
  a: BootstrapAccount,
  b: BootstrapAccount,
) => {
  const startDateA = getEarliestSupplyStartDate(a);
  const startDateB = getEarliestSupplyStartDate(b);

  if (!startDateA || !startDateB) return 0;

  return new Date(startDateB).getTime() - new Date(startDateA).getTime();
};
