import {
  Heading4,
  Margin,
  P,
  SecondaryCTAButton,
  Strong,
  theme,
  VisuallyHidden,
} from '@ovotech/nebula';

import React from 'react';
import { Link } from 'react-router-dom-v5';
import { carbonIntensityAssets } from '../../../CarbonIntensity/Components/CurrentIntensity/CurrentIntensity.assets';
import { hubTestIDs as testIDs } from '../../testIDs';

import * as S from './CarbonIntensityWidget.styled';
import { ROUTE_ENERGY_INSIGHTS } from '@/src/constants/routes';
import { ENERGY_INSIGHTS } from '@/src/constants/analytics';
import { GridContainer } from '@/src/pages/EnergyInsights/components';
import {
  CarbonIntensityLevel,
  NextGreenTimes,
} from '@/src/pages/EnergyInsights/types';
import AnalyticsService from '@/src/services/analyticsService';

type Props = {
  currentIntensity?: CarbonIntensityLevel;
  greentime?: NextGreenTimes | null;
};

const CarbonIntensityWidget = ({ currentIntensity, greentime }: Props) => {
  if (!currentIntensity) {
    return null;
  }

  const { topText, icon, indicatorBadge } =
    carbonIntensityAssets[currentIntensity];

  return (
    <GridContainer
      spanSmall={12}
      spanMedium={6}
      spanLarge={6}
      includeRow={false}
    >
      <S.CarbonIntensityWidget data-testid={testIDs.carbonIntensityWidget}>
        <Heading4 style={{ margin: 0 }}>Power up at greener times</Heading4>
        <P style={{ margin: `0 0 ${theme.space[2]} 0` }}>
          Find out when the energy grid is producing the most planet-friendly
          power.
        </P>
        <S.CarbonIntensityCard>
          <Heading4>{topText}</Heading4>
          {icon}
          <S.CurrentIntensityIndicator>
            <Margin bottom={2}>
              Carbon Intensity on the grid is
              <VisuallyHidden>&nbsp;{currentIntensity}</VisuallyHidden>
            </Margin>

            {indicatorBadge}
          </S.CurrentIntensityIndicator>
          {greentime?.start && greentime.day ? (
            <P>
              Next low carbon time will be <Strong>{greentime.day}</Strong> at{' '}
              <Strong>{greentime.start}</Strong>
            </P>
          ) : null}

          <Margin bottom={1} top={3}>
            <SecondaryCTAButton
              iconRight="chevron-right"
              as={Link}
              to={ROUTE_ENERGY_INSIGHTS.CARBON_INTENSITY}
              onClick={() => {
                AnalyticsService.dispatch(
                  ENERGY_INSIGHTS.BUTTON_CLICK_HUB_NAVIGATE_TO_CARBON_INTENSITY,
                );
              }}
            >
              See 48 hrs carbon forecast
            </SecondaryCTAButton>
          </Margin>
        </S.CarbonIntensityCard>
      </S.CarbonIntensityWidget>
    </GridContainer>
  );
};

export default CarbonIntensityWidget;
