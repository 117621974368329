import {
  disconnectKaluzaSmartChargerDataEpic,
  getKaluzaSmartChargerMonthlyDataEpic,
  sendKaluzaTokenDataEpic,
  sendKaluzaAuthTokenDataEpic,
  connectionStatusToKaluzaSmartChargerDataEpic,
  getVehicleDataFromKaluzaSmartChargerDataEpic,
  getSavingsAndInsightDataFromKaluzaSmartChargerDataEpic,
  getAnytimeSavingsDataFromKaluzaSmartChargerDataEpic,
} from './ducks/evs/evInlife';
import { apiErrorEpic, resetApiErrorEpic } from './ducks/apiError/apiError';
import {
  activateProductEpic,
  activateProductSuccessEpic,
  cancelProductEpic,
  getProductCatalogueEpic,
  getProductsEpic,
} from './ducks/products/products';
import { getRecommendedDirectDebitEpic } from './ducks/recommendedDirectDebit/recommendedDirectDebit';
import {
  getShowSmartMeterBookingBannerEpic,
  getSmartMeterBookingEpic,
} from './ducks/smartMeterBooking/smartMeterBooking';
import { fetchTariffCostsEpic } from './ducks/tariffCosts/tariffCosts';
import { getDateRangeMonthlyUsageEpic } from './ducks/usage/monthly-usage';
import {
  activateAnytimeProductEpic,
  cancelAnytimeProductEpic,
} from '@/src/redux/ducks/evs/evAnytime';

const epics = [
  getDateRangeMonthlyUsageEpic,
  apiErrorEpic,
  resetApiErrorEpic,
  getProductsEpic,
  getProductCatalogueEpic,
  activateProductEpic,
  activateProductSuccessEpic,
  cancelProductEpic,
  getRecommendedDirectDebitEpic,
  getShowSmartMeterBookingBannerEpic,
  getSmartMeterBookingEpic,
  fetchTariffCostsEpic,
  disconnectKaluzaSmartChargerDataEpic,
  getKaluzaSmartChargerMonthlyDataEpic,
  sendKaluzaTokenDataEpic,
  sendKaluzaAuthTokenDataEpic,
  connectionStatusToKaluzaSmartChargerDataEpic,
  getVehicleDataFromKaluzaSmartChargerDataEpic,
  getSavingsAndInsightDataFromKaluzaSmartChargerDataEpic,
  getAnytimeSavingsDataFromKaluzaSmartChargerDataEpic,
  activateAnytimeProductEpic,
  cancelAnytimeProductEpic,
];

export default epics;
