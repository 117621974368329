import { PlanFamily } from '../../planConstants';
import { GeminiUnitRate } from './plan';

export type FuelType = 'electricity' | 'gas';
export type MeterMode = 'Smart' | 'Credit' | 'Prepayment' | 'Unknown';
export enum EnergyPaymentMethod {
  Paym = 'Paym',
  Payg = 'Payg',
  PayNonDD = 'PayNonDD',
  PayMixed = 'PayMixed',
}
export type UsageType =
  | 'anytime'
  | 'day'
  | 'night'
  | 'peak'
  | 'offpeak'
  | 'heating'
  | 'evening and weekend';

/**
 * @property P1 - 24/7 Rate
 * @property P2 - Different Day / Night (7 or 10 hours)
 * @property P1P2 - Same Day / Night (7 or 10 hours)
 */
export type ProfileClass = 'P1' | 'P2' | 'P1P2';

export type ContractId = string & { __nominal__: 'contractId' };
export type Milliseconds = number & { __nominal__: 'milliseconds' };
export type Pence = number & { __nominal__: 'pence' };
export type Pound = number & { __nominal__: 'pound' };
export type Kwh = number & { __nominal__: 'kwh' };
export type Months = number & { __nominal__: 'months' };

type FixedContractProperties = {
  type: 'fixed';
  endDate: Milliseconds;
};

type VariableContractProperties = {
  type: 'variable';
  endDate: Milliseconds | null;
};

type HHContractProperties = {
  type: 'half-hourly';
  endDate: Milliseconds | null;
};

export type GeminiEnergyContract = {
  legalName: string;
  name: string;
  planFamily: PlanFamily;
  contractId: ContractId;
  mpxn: string;
  startDate: Milliseconds;
  fuel: FuelType;
  standingCharge: Pence;
  standingChargeWithVat: Pence;
  exitFee: Pence;
  length: Months | null;
  region: string;
  profileClass: ProfileClass;
  status: {
    active: boolean;
    inRenewal: boolean;
    hasFutureContracts: boolean;
  };
  unitRates: GeminiUnitRate[];
  isKapi: boolean;
  projections: {
    annualCostWithVat: Pence | null;
    /**
     * The total usage across all timing categories
     */
    annualUsage: Kwh | null;
  };
  /**
   * The modes of the active meters
   */
  meterModes: MeterMode[];
  paymentMethod: Omit<EnergyPaymentMethod, 'PayMixed'>;
} & (
  | FixedContractProperties
  | VariableContractProperties
  | HHContractProperties
);
