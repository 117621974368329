import {
  Reason,
  StyledTitle,
} from '@/src/pages/AnytimeEligibility/pages/Fallout/Fallout.styled';
import { P } from '@ovotech/nebula';
import { ReturnToHomePage } from '@/src/components/ReturnToHomePage/ReturnToHomePage';
import React from 'react';

export const FalloutOther = () => {
  return (
    <Reason data-testid="invalid-other">
      <StyledTitle>
        Sorry, we can’t bring you Charge Anytime at the moment.
      </StyledTitle>
      <P>
        We’re working hard to make Charge Anytime available to more customers.
      </P>
      <P>
        Please check back soon to see if Charge Anytime is available for you.
      </P>
      <ReturnToHomePage />
    </Reason>
  );
};
