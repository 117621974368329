import { Tracker, Event } from '@ovotech/ui-tools';
import { MixpanelEventsQueue } from '../../mixpanel-utils';

const convertCase = (str: string) => {
  return `${str.slice(0, 1).toUpperCase()}${str.slice(1)}`;
};

const nameEvent = (event: Event): string => {
  const legacyName = event?.properties?.legacyName;

  if (legacyName) {
    return legacyName;
  }

  return `${convertCase(event.type)}-${convertCase(event.name)} [${
    event.data.project.name
  }]`;
};

const removeLegacyName = (event: Event) => {
  if (event.properties) {
    return Object.keys(event.properties as Record<string, any>).reduce(
      (acc: Record<string, any>, k: string) => {
        if (
          k !== 'legacyName' &&
          event.properties &&
          event.properties[k] !== undefined
        ) {
          acc[k] = event.properties[k];
        }
        return acc;
      },
      {},
    );
  }
  return {};
};

const transformData = (event: Event) => {
  const properties = removeLegacyName(event);
  return {
    ...properties,
    ...event.data,
    brand: 'ORION',
    brandEntity: 'OVO Energy',
  };
};

const ensureStructure = (event: Event) => {
  return {
    name: nameEvent(event),
    type: event.type,
    properties: event.properties || {},
    data: {
      account: event.data.account || {},
      project: event.data.project || {},
      version: event.data.version,
      /* TODO RTN-1757: Remove data - hack to get around inability to apply it to page properties */
      ...(event.data.experiments
        ? { experiments: event.data.experiments }
        : {}),
    },
  };
};

export const mixpanelTracker: Tracker = {
  dispatch: (event: Event) => {
    const safeEvent = ensureStructure(event);
    MixpanelEventsQueue.track(safeEvent.name, transformData(safeEvent));
  },
};
