import { Heading3, P, Strong } from '@ovotech/nebula';
import React from 'react';
import Offer from './Offer/Offer';
import image from '@/src/resources/offers/homeHeat.jpg';

export const HomeHeatOffer = () => {
  // TODO: check if reference to smart thermostat can be removed
  return (
    <Offer
      image={image}
      offerId="homeHeat"
      linkUrl="tel:08000856398"
      linkText="Call on 0800 085 6398"
    >
      <Heading3 as="h2">
        Next on the list: cut carbon and save money with a new boiler
      </Heading3>
      <P>
        Zap money off your energy bills by upgrading to a modern A-rated boiler:{' '}
        <Strong>prices from just {'\xA3'}2,399</Strong>.
      </P>
      <P>
        <Strong>A smart thermostat worth up to {'\xA3'}199 is included</Strong>.
        And we&rsquo;ve got installation dates to suit you. Buying a new boiler
        just got interesting.
      </P>
      <P>Over to our good friends at CORGI HomeHeat to tell you more…</P>
    </Offer>
  );
};
