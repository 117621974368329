import { NbaChannel } from '@/src/types/NextBestAction';
import { ProductId } from '@/src/types/Products';

export const RESET_PASSWORD_URL = process.env.NX_RESET_PASSWORD_URL;
export const SMART_BOOKING_URL = process.env.NX_SMART_BOOKING_URL;
export const GEMINI_URL = process.env.NX_GEMINI_URL;
export const GEMINI_API_URL = process.env.NX_GEMINI_API_URL;

export const KAPI_URL = process.env.NX_KAPI_URL;
export const TOKEN_URL = process.env.NX_TOKEN_URL;
export const LOGOUT_URL = process.env.NX_LOGOUT_URL ?? '';

const KONG_API_ROOT = process.env.NX_KONG_BASE_URL;
const OREX_APIS_ROOT = `${KONG_API_ROOT}/orex/api`;
const PRODUCTS_API_ROOT = `${KONG_API_ROOT}/green`;
const PRODUCTS_AND_TARIFFS_ROOT = process.env.NX_PRODUCTS_AND_TARIFFS_URL;
const USAGE_ROOT = `${KONG_API_ROOT}/usage`;
const NBA_ROOT = `${KONG_API_ROOT}/nba`;
const ENDPOINT_MOSS_API = `${process.env.NX_MOSS_API_URL}/api`;
const ENDPOINT_CARBON_ZERO_BFF = `${KONG_API_ROOT}/carbon-bff`;
export const createContractsUrl = (accountId: string): string =>
  `${OREX_APIS_ROOT}/contracts/${accountId}`;
export const ENDPOINT_TARIFF_COSTS = `${OREX_APIS_ROOT}/tariff-costs`;
export const createTariffCostsUrl = (accountId: string): string =>
  `${OREX_APIS_ROOT}/tariff-costs/${accountId}`;
export const CHARGE_ANYTIME_API_ROOT = process.env.NX_CHARGE_ANYTIME_URL;

export const USAGE_ROUTES = {
  HALF_HOURLY_USAGE: `${USAGE_ROOT}/api/half-hourly`,
  DAILY_USAGE: `${USAGE_ROOT}/api/daily`,
  MONTHLY_USAGE: `${USAGE_ROOT}/api/monthly`,
};

export const EVS_INLIFE_ENDPOINT = `${KONG_API_ROOT}/inlife/v1`;

export const createProductsEndpoint = (accountId: string): string =>
  `${PRODUCTS_API_ROOT}/account/${accountId}/products`;
export const createProductsEndpointV2 = (accountId: string): string =>
  `${PRODUCTS_API_ROOT}/v2/account/${accountId}/products`;
/**
 * @deprecated deprecated endpoint, use /v2/account/{accountId} instead
 */
export const createProductActivateEndpoint = (accountId: string): string =>
  `${PRODUCTS_API_ROOT}/account/${accountId}`;
export const createProductActivateEndpointV2 = (accountId: string): string =>
  `${PRODUCTS_API_ROOT}/v2/account/${accountId}`;
export const createProductActivateEndpointV3 = (accountId: string): string =>
  `${PRODUCTS_API_ROOT}/v3/account/${accountId}`;
export const createProductCatalogueEndpoint = (
  accountId: string,
  promoCode?: string,
): string =>
  `${PRODUCTS_API_ROOT}/account/${accountId}/catalogue${
    promoCode ? `?promoCode=${promoCode}` : ''
  }`;
export const createProductCatalogueEndpointV2 = (
  accountId?: string,
  promoCode?: string,
): string =>
  `${PRODUCTS_API_ROOT}/v2/account/${accountId}/catalogue${
    promoCode ? `?promoCode=${promoCode}` : ''
  }`;
export const createProductCancelEndpoint = (
  accountId: string,
  productId: ProductId,
  versionId?: string,
): string => {
  const baseEndpoint = `${PRODUCTS_API_ROOT}/account/${accountId}/products/${productId}`;

  if (versionId) return `${baseEndpoint}?versionId=${versionId}`;
  return baseEndpoint;
};
export const createPlansEndpoint = (accountId: string) =>
  `${OREX_APIS_ROOT}/plans/${accountId}`;
export const createAvailablePlansEndpoint = (accountId: string) =>
  `${OREX_APIS_ROOT}/available-plans/${accountId}`;
export const createRenewablePlansEndpoint = (accountId: string) =>
  `${OREX_APIS_ROOT}/renewable-plans/${accountId}`;
export const createRenewPlanEndpoint = (serviceAccountId: string) =>
  `${OREX_APIS_ROOT}/contracts/${serviceAccountId}/renew`;

export const createProjectedCostsEndpoint = (accountId: string): string =>
  `${KONG_API_ROOT}/pace/recommended-dds/v1/${accountId}/projected-costs`;

export const createRecommendedDirectDebitEndpoint = (
  accountId: string,
): string =>
  `${KONG_API_ROOT}/pace/recommended-dds/v1/${accountId}/recommended-direct-debit`;

export const createGetDirectDebitEndpoint = (accountId: string): string =>
  `${KONG_API_ROOT}/pace/payments/v1/${accountId}/direct-debit`;

export const createHalfHourlyUsageEndpoint = (
  accountId: string,
  date: string,
): string => `${USAGE_ROUTES.HALF_HOURLY_USAGE}/${accountId}?date=${date}`;

export const createDailyUsageEndpoint = (
  accountId: string,
  date: string,
): string => `${USAGE_ROUTES.DAILY_USAGE}/${accountId}?date=${date}`;

export const createMonthlyUsageEndpoint = (
  accountId: string,
  date: string,
): string => `${USAGE_ROUTES.MONTHLY_USAGE}/${accountId}?date=${date}`;

export const createAccountInfoEndpoint = (accountId: string) =>
  `${OREX_APIS_ROOT}/account/${accountId}/info`;

export const createMoveOutEndpoint = (accountId: string) =>
  `${KONG_API_ROOT}/homemoves/v1/move-out-date/${accountId}`;

const BAL_API_ROOT = `${KONG_API_ROOT}/bal`;
export const ENDPOINT_BILLING_GRAPHQL = `${BAL_API_ROOT}/api/graphql`;
export const createFinalStatementEndpoint = (accountId: string) =>
  `${BAL_API_ROOT}/final-statement-state/account/${accountId}`;

/* The statement endpoints rely on url rewrite that don't exist in Kong
 * https://github.com/ovotech/orion-ui/tree/master/nginx
 */

export const createGetSmartMeterBookingEndpoint = (accountId: string) =>
  `${KONG_API_ROOT}/api/v2/marketing?accountId=${accountId}&jobType=Credit`;

export const createGetSmartMeterBookabilityEndpoint = (accountId: string) =>
  `${KONG_API_ROOT}/v2/smart-meter-booking/bookability/${accountId}`;

export const createGetShowSmartMeterBookingBannerEndpoint = (
  accountId: string,
) =>
  `${KONG_API_ROOT}/api/v2/marketing/banner?jobType=Credit&accountId=${accountId}`;

export const createGetNextBestActionEndpoint = (
  accountId: string,
  channel: NbaChannel,
) =>
  `${NBA_ROOT}/get_next_best_actions?ACCOUNT_NO=${accountId}&CHANNEL=${channel}`;

export const createPostNextBestActionEndpoint = `${NBA_ROOT}/post_action_response`;

export const createEncryptedKantanPayloadEndpoint = (
  accountId: string,
): string =>
  `${ENDPOINT_MOSS_API}/v1/account/${accountId}/energy-experts/encrypt-kantan-payload`;

export const ENDPOINT_ADDRESS_LOOKUP = `${KONG_API_ROOT}/address/address`;

export const createAddressLookupEndpoint = (postcode: string) =>
  `${GEMINI_API_URL}/address-lookup?provider=gbgv2&postcode=${postcode}`;

export const ACCOUNT_EXPERIENCE_FAQS =
  'https://www.ovoenergy.com/new-online-account-experience-faq';

export const ENDPOINT_CARD_PAYMENT = `${ENDPOINT_MOSS_API}/v1/payments/cards/payment-session`;

export const createCollectableBalanceEndpoint = (accountId: string) =>
  `${ENDPOINT_MOSS_API}/v1/account/${accountId}/collectable-balance`;

export const OVO_QUOTE_AND_SWITCH = 'https://switch.ovoenergy.com';

export const createEnergyExpertsJourneyUrl = (token: string): string =>
  `${process.env.NX_KANTAN_ENERGY_EXPERTS_JOURNEY_URL}?token=${token}`;

export const createKantanOceanSavingSolutionsJourneyUrl = (
  token: string,
): string =>
  `${process.env.NX_KANTAN_OCEAN_SAVINGS_SOLUTIONS_JOURNEY_URL}?token=${token}&utm_medium=banner&utm_source=offers-and-upgrades&utm_campaign=energy-experts`;

// This endpoint returns a list of products associated with the mandatory ids query parameter.
export const createAvailableProductsEndpoint = (
  productId: string,
  versionId?: string | null,
  promoCredit?: string | null,
): string => {
  if (promoCredit && versionId) {
    return `${PRODUCTS_AND_TARIFFS_ROOT}/v4/products?id=${productId}&id=${versionId}&promoCredit=${promoCredit}`;
  }
  if (promoCredit) {
    return `${PRODUCTS_AND_TARIFFS_ROOT}/v4/products?id=${productId}&promoCredit=${promoCredit}`;
  }
  let ids = productId;
  if (versionId) ids = `${productId},${versionId}`;
  return `${PRODUCTS_AND_TARIFFS_ROOT}/v3/products?ids=${ids}`;
};

const carbonZeroBffUrlWithAccountId = (path: string, accountId: string) =>
  `${ENDPOINT_CARBON_ZERO_BFF}/${accountId}/${path}`;

export const createMetersEndpoint = (accountId: string): string =>
  `${ENDPOINT_MOSS_API}/v1/account/${accountId}/meters`;

export const carbonZeroBffEndpoints = {
  getCarbonIntensity: `${ENDPOINT_CARBON_ZERO_BFF}/carbonintensity`,

  homeProfileWidget: (accountId: string) =>
    `${ENDPOINT_CARBON_ZERO_BFF}/${accountId}/home-profile-widget`,

  putHomeProfile: (accountId: string) =>
    `${ENDPOINT_CARBON_ZERO_BFF}/${accountId}/home-profile`,

  getHub: (accountId: string) =>
    carbonZeroBffUrlWithAccountId('hub', accountId),

  putHalfHourlyOptIn: (accountId: string) =>
    `${ENDPOINT_CARBON_ZERO_BFF}/${accountId}/usage/half-hourly-opt-in`,

  getBreakdownPage: (accountId: string) =>
    carbonZeroBffUrlWithAccountId('web/breakdown-page', accountId),

  getWebNavigation: (accountId: string) =>
    carbonZeroBffUrlWithAccountId('web/navigation', accountId),
};

export const createHomePlanUrl = (
  source?: string | null,
  accountId?: string | null,
  extra?: string,
) => {
  const url =
    process.env.NX_HOMEPLAN_URL +
    (!source ? '' : `?source=${source}`) +
    (!accountId ? '' : `&accountId=${accountId}`);

  if (extra) {
    return url + extra;
  }

  return url;
};

export const createHomeRecoverUrl = (
  source: string,
  accountId?: string | null,
) =>
  `${process.env.NX_HOMEPLAN_URL}/home-recover/?source=${source}` +
  (!accountId ? '' : `&accountId=${accountId}`);

export const TESCO_REWARDS_OFFER_URL = `${process.env.NX_REWARD_ENDPOINT}/in-life/v1/offers/ovoxtesco`;

export const createTescoRewardsGetUrl = (accountId: string) =>
  `${TESCO_REWARDS_OFFER_URL}?accountNumber=${accountId}`;

export const TESCO_REWARDS_AUTH_URL = `${process.env.NX_REWARD_ENDPOINT}/in-life/v1/partners/tesco/auth`;

export const BASS_API_URL = `${process.env.NX_BASS_BASE_URL}/graphql`;

export const createChargeAnytimeActivationUrl = () =>
  `${CHARGE_ANYTIME_API_ROOT}/v1/acquisition`;

export const createChargeAnytimeDevicesUrl = (
  deviceType: 'all' | 'vehicles' | 'chargers',
) => `${CHARGE_ANYTIME_API_ROOT}/v1/devices/${deviceType}`;
