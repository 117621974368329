import { SmartChargers } from './anytime-chargers';
import { VehicleDevice } from './types';

export type Make = string;
export type Model = string;

export type Type = 'PHEV' | 'BEV';

export type Status = 'BETA ELIGIBLE' | 'FULLY ELIGIBLE' | 'INELIGIBLE';

const VWGMakes = ['Audi', 'Volkswagen', 'Skoda', 'Cupra'];

export type Vehicle = {
  deviceId: string;
  make: Make;
  model: Model;
  yearFrom?: number;
  years?: Array<number>;
  type: Type;
  status: Status;
};

const vehicles: Vehicle[] = [
  {
    deviceId: 'cda4ee6f-62c4-4814-ab10-fffe2eb33e0d',
    make: 'Audi',
    model: 'A3 e-tron',
    yearFrom: 2015,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: 'b21dc8d2-bcf1-4b5b-9694-381a3fa968b5',
    make: 'Audi',
    model: 'A3 TFSI e',
    yearFrom: 2021,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '8d2880ce-3a81-452c-8064-c8033c30c06c',
    make: 'Audi',
    model: 'A6 TFSI e',
    yearFrom: 2020,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '2ccd89c6-f3cd-447b-9300-f424f4de88cd',
    make: 'Audi',
    model: 'A7 TFSI e',
    yearFrom: 2020,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: 'd349559c-ed48-4442-a306-c7d617d39364',
    make: 'Audi',
    model: 'A8 TFSI e',
    yearFrom: 2020,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '4925eb83-b437-4793-bf5a-213949b14419',
    make: 'Audi',
    model: 'e-tron',
    yearFrom: 2019,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '770321f5-6121-4d17-a66f-3e6eb048281b',
    make: 'Audi',
    model: 'Q3 TFSI e',
    yearFrom: 2021,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: 'c9718dc2-0caa-4473-bfa9-f3ccdbabf55c',
    make: 'Audi',
    model: 'Q4 e-tron',
    yearFrom: 2022,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '8356326b-f7a0-42db-bb43-569554f82712',
    make: 'Audi',
    model: 'Q5 TFSI e',
    yearFrom: 2019,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '9c82fe7e-d990-4d43-8387-054f466e54ee',
    make: 'Audi',
    model: 'Q6 e-tron',
    yearFrom: 2024,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '9e419bfd-02ab-4f89-93f8-18a024a0f0c7',
    make: 'Audi',
    model: 'Q7 e-tron',
    yearFrom: 2016,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '31673587-7b38-4a53-97da-f28b8ed9a2c8',
    make: 'Audi',
    model: 'Q7 TFSI e',
    yearFrom: 2020,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '3d244b92-ec53-445a-8d13-c7d6d3488e4c',
    make: 'Audi',
    model: 'Q8 e-tron',
    yearFrom: 2024,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: 'cae3b484-dc57-4258-8438-086e6f409edb',
    make: 'Audi',
    model: 'Q8 TFSI e',
    yearFrom: 2021,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '43645f89-4655-490a-a3df-9c98c8fc486b',
    make: 'Audi',
    model: 'RS e-tron GT',
    yearFrom: 2022,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '348c8116-f7fc-44e5-a0d5-00b8597149c7',
    make: 'Audi',
    model: 'SQ6 e-tron',
    yearFrom: 2024,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '133b891f-3b72-4000-ba2a-b26a57840d71',
    make: 'Audi',
    model: 'SQ8 e-tron',
    yearFrom: 2024,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: 'b261eff1-af7f-4294-81ca-3f78a24e9480',
    make: 'BMW',
    model: '225e',
    yearFrom: 2022,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '285fcee0-2fe5-473c-8ae2-4aa2449caabb',
    make: 'BMW',
    model: '225xe',
    yearFrom: 2015,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: 'f86ad186-2349-492c-b8e0-a5cff849f0ba',
    make: 'BMW',
    model: '230e',
    yearFrom: 2022,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '337d7650-ace1-4ec6-890c-61df7e711c83',
    make: 'BMW',
    model: '320e',
    yearFrom: 2021,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '22d6ec5f-a9e8-4430-a9f7-d380ec8df326',
    make: 'BMW',
    model: '330e',
    yearFrom: 2015,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '1eb0bc39-7d7b-4843-85c4-7c6f3c3937a5',
    make: 'BMW',
    model: '520e',
    yearFrom: 2021,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: 'f709635a-997b-4ef4-b76d-2a62169b3f88',
    make: 'BMW',
    model: '530e',
    yearFrom: 2017,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '853d1bed-9d52-4559-a82a-e19ef49b7e9c',
    make: 'BMW',
    model: '545e',
    yearFrom: 2020,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '5e07e82d-4068-4dc2-9975-96f02cc6007c',
    make: 'BMW',
    model: '550e',
    yearFrom: 2023,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '3cf8dba0-04c8-4b73-94db-7b0ac24c982c',
    make: 'BMW',
    model: '740e',
    yearFrom: 2016,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '464680b1-798d-447b-9be0-8014601ecea8',
    make: 'BMW',
    model: '740Le',
    yearFrom: 2016,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '6f72da57-a37d-41f7-bfca-51e7ddf9da7d',
    make: 'BMW',
    model: '745e',
    yearFrom: 2019,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: 'b97345b2-5a4f-47d1-a0e0-536b9c522de6',
    make: 'BMW',
    model: '745Le',
    yearFrom: 2019,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: 'c6fcba8b-3cc9-4aa2-96dc-1b37cb5b01f3',
    make: 'BMW',
    model: '750e',
    yearFrom: 2022,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '8fa41441-77c3-4ed9-9bc2-334f10cc9074',
    make: 'BMW',
    model: 'i3',
    yearFrom: 2013,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '3ff62f9d-d324-4b57-94a9-6d89a0280124',
    make: 'BMW',
    model: 'i3 Hybrid',
    yearFrom: 2013,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '38a00187-4532-462f-a6e2-0b67fc336b20',
    make: 'BMW',
    model: 'i3s',
    yearFrom: 2017,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '570ac77e-9555-4d13-ba44-4687db897889',
    make: 'BMW',
    model: 'i3s Hybrid',
    yearFrom: 2017,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '08a7e16f-300b-4c07-b1cd-dc82fe60a169',
    make: 'BMW',
    model: 'i4',
    yearFrom: 2021,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '74cc4134-fd74-4ef1-9991-67c15d843393',
    make: 'BMW',
    model: 'i5',
    yearFrom: 2023,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '202903a5-34cc-435f-be1f-e6e898001cc3',
    make: 'BMW',
    model: 'i7',
    yearFrom: 2022,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: 'f993b676-7a9a-4a6c-9b43-50a9fce4bacc',
    make: 'BMW',
    model: 'i8',
    yearFrom: 2014,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: 'c37c885c-89b5-4d9e-9b05-a6318d8384c2',
    make: 'BMW',
    model: 'iX',
    yearFrom: 2021,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '36544aa3-b50a-408b-8a47-d843e6b6d182',
    make: 'BMW',
    model: 'iX1',
    yearFrom: 2022,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: 'a8d23441-273e-4920-b71d-3d32a3021342',
    make: 'BMW',
    model: 'iX2',
    yearFrom: 2023,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '67562225-5b28-4b84-bce4-bfb7fa5051e3',
    make: 'BMW',
    model: 'iX3',
    yearFrom: 2020,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: 'cb70cbfd-5456-4e7c-8696-d23a49ff79da',
    make: 'BMW',
    model: 'M5',
    yearFrom: 2024,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: 'b2992bec-7eef-49b6-a5b6-fd1267a08d01',
    make: 'BMW',
    model: 'M760e',
    yearFrom: 2022,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: 'dc0f2050-f63c-4a7b-bb1b-2f1ffd081d94',
    make: 'BMW',
    model: 'X1',
    yearFrom: 2020,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '680384d7-136e-47ee-96ac-5c49b7428558',
    make: 'BMW',
    model: 'X2',
    yearFrom: 2020,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: 'e22d51d5-300d-4b4a-85c2-853d613c9335',
    make: 'BMW',
    model: 'X3',
    yearFrom: 2019,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '6196fa71-a89e-42f8-8a24-ebfe854bb01c',
    make: 'BMW',
    model: 'X5',
    yearFrom: 2015,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '0fc9eea0-5842-4de7-a628-a9e4d2f26cde',
    make: 'BMW',
    model: 'XM',
    yearFrom: 2022,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: 'bf1cfd03-5f24-40fc-abd0-7647b7b9c7af',
    make: 'Cadillac',
    model: 'Lyriq',
    yearFrom: 2023,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: 'b2785e10-87ad-4a59-81f6-1c93ec86df84',
    make: 'Chevrolet',
    model: 'Blazer EV',
    yearFrom: 2024,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '2d32c735-eae8-4a49-b294-7f9884495a4a',
    make: 'Chevrolet',
    model: 'Bolt EUV',
    yearFrom: 2022,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '09606369-ec73-4bf2-bda9-0e1447a83995',
    make: 'Chevrolet',
    model: 'Bolt EV',
    yearFrom: 2017,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '2140e2c1-5e8b-4c25-81aa-7bc0b8b8d2c8',
    make: 'Chevrolet',
    model: 'Volt',
    yearFrom: 2019,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '3e771967-a5d3-4df2-86ea-e0f6459fa105',
    make: 'Citroën',
    model: 'BERLINGO (VAN)',
    yearFrom: 2022,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '1deabb99-2262-4756-99c0-1469aa0cba5e',
    make: 'Citroën',
    model: 'C5 AIRCROSS',
    yearFrom: 2020,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '0ee6ec19-83c0-4bd3-8512-fb3cc1be386e',
    make: 'Citroën',
    model: 'C5 X',
    yearFrom: 2022,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '4a251f56-af8a-4bc6-aaa1-48f70a3ec112',
    make: 'Citroën',
    model: 'Ë-BERLINGO',
    yearFrom: 2021,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: 'c8fe0e1c-bbc7-4a93-9eaf-ea448a0ac49e',
    make: 'Citroën',
    model: 'Ë-C4',
    yearFrom: 2020,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '21361a92-133c-4039-a933-50ee22434dc7',
    make: 'Citroën',
    model: 'Ë-C4X',
    yearFrom: 2022,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '2eb31e40-61ba-4041-a7c7-e113613487d9',
    make: 'Citroën',
    model: 'Ë-Jumpy',
    yearFrom: 2020,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: 'dcbc2e79-4d93-4c0e-99e8-277cbcd34fb2',
    make: 'Citroën',
    model: 'Ë-SPACETOURER',
    yearFrom: 2020,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '76f44877-5a3e-471d-b267-c2dfee78e394',
    make: 'Cupra',
    model: 'Born',
    yearFrom: 2022,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '0d768198-181b-4c01-a273-8ffb76664288',
    make: 'Cupra',
    model: 'Formentor',
    yearFrom: 2021,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: 'caa0540e-817c-4f05-b301-5dda855ad31f',
    make: 'Cupra',
    model: 'Leon',
    yearFrom: 2021,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: 'f64a442d-569d-4cd6-a123-b1c4bbf8049d',
    make: 'Cupra',
    model: 'Tavascan',
    yearFrom: 2024,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: 'c2dc2c31-e49d-49c9-b423-d4fd313b9298',
    make: 'Cupra',
    model: 'Terramar',
    yearFrom: 2025,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '7352e6e2-f1a2-4aa1-bbd5-92d004129811',
    make: 'DS',
    model: 'DS 3',
    yearFrom: 2021,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '94b1f777-3585-40e2-a8af-728490d2c257',
    make: 'DS',
    model: 'DS 3 CROSSBACK E-TENSE',
    yearFrom: 2019,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '800843f7-d428-4382-8b92-9333ebd386b6',
    make: 'DS',
    model: 'DS 4',
    yearFrom: 2021,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: 'cc76f5ac-7f40-4df8-b912-241a41ae9251',
    make: 'DS',
    model: 'DS 4 E-TENSE',
    yearFrom: 2021,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: 'f27e7435-7f98-4edd-af2b-da5d2500c88e',
    make: 'DS',
    model: 'DS 7',
    yearFrom: 2021,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '87ebda34-8548-4f14-b2af-3e1a5abb092f',
    make: 'DS',
    model: 'DS 7 CROSSBACK E-TENSE 4x4',
    yearFrom: 2019,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '551a8c63-3251-421d-a807-cab85bb3ed3c',
    make: 'DS',
    model: 'DS 9',
    yearFrom: 2021,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: 'b3c80b9f-6913-4ced-827e-582a7ddad710',
    make: 'Fiat',
    model: '500BEV',
    yearFrom: 2021,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '3fe3b128-000d-4442-95c1-3fc3ff209cff',
    make: 'Fiat',
    model: '600e',
    yearFrom: 2023,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '9df8ed30-7016-43c7-b4e0-f6b9a12c3a71',
    make: 'Ford',
    model: 'Escape',
    yearFrom: 2021,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: 'f23bba35-bfd6-4b84-be27-7d68b108dfcc',
    make: 'Ford',
    model: 'Explorer',
    yearFrom: 2020,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: 'af5bdd6b-50f4-4ab4-a5e5-d19408fc3b2d',
    make: 'Ford',
    model: 'F-150',
    yearFrom: 2022,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: 'ed83c13a-f722-4e02-a6bc-bf3499e38750',
    make: 'Ford',
    model: 'Focus Electric',
    yearFrom: 2013,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '3188325b-87b1-4caa-b893-7f70c77732aa',
    make: 'Ford',
    model: 'Fusion Hybrid',
    yearFrom: 2019,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: 'aab9f49c-b43c-474a-bfa4-b1c094c500af',
    make: 'Ford',
    model: 'Kuga',
    yearFrom: 2020,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: 'ae94fc73-48d0-4dcf-8004-a6d0a5c694bc',
    make: 'Ford',
    model: 'Kuga Vignale',
    yearFrom: 2020,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '95c364c8-b070-4d7d-bf7a-3fb5aea7af3e',
    make: 'Ford',
    model: 'Mustang Mach-E',
    yearFrom: 2021,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '4538df09-9174-46a4-aeb8-d6bd4e719e21',
    make: 'Ford',
    model: 'Tourneo Custom',
    yearFrom: 2022,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: 'e8cd56df-4bdc-4b03-a2a6-0cace30e6175',
    make: 'Ford',
    model: 'Transit',
    yearFrom: 2022,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: 'b8c28c03-5f5f-4f09-bc7a-a771ed960beb',
    make: 'Ford',
    model: 'Transit Custom',
    yearFrom: 2021,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: 'c799d5fe-6817-4728-98be-bfbbce5d46d0',
    make: 'GMC',
    model: 'HUMMER EV',
    yearFrom: 2022,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '3a1a85f9-e573-4f92-b065-727cb158f55d',
    make: 'Hyundai',
    model: 'INSTER',
    yearFrom: 2025,
    type: 'BEV',
    status: 'BETA ELIGIBLE',
  },
  {
    deviceId: '6e608a6a-f128-4256-aea1-0cab5be11e87',
    make: 'Hyundai',
    model: 'IONIQ',
    yearFrom: 2017,
    type: 'BEV',
    status: 'BETA ELIGIBLE',
  },
  {
    deviceId: '57a4a103-2b17-46a9-a6df-c2c23f8a4bf1',
    make: 'Hyundai',
    model: 'IONIQ 5',
    yearFrom: 2021,
    type: 'BEV',
    status: 'BETA ELIGIBLE',
  },
  {
    deviceId: '026b58c6-060f-4112-a74b-30aad8c192f1',
    make: 'Hyundai',
    model: 'IONIQ 6',
    yearFrom: 2023,
    type: 'BEV',
    status: 'BETA ELIGIBLE',
  },
  {
    deviceId: '10e8b317-ba6c-4833-89e2-d4654e4db722',
    make: 'Hyundai',
    model: 'IONIQ Hybrid',
    yearFrom: 2019,
    type: 'PHEV',
    status: 'BETA ELIGIBLE',
  },
  {
    deviceId: '09a0adaa-efc1-4a80-90ae-538dbd49ef19',
    make: 'Hyundai',
    model: 'KONA',
    yearFrom: 2019,
    type: 'BEV',
    status: 'BETA ELIGIBLE',
  },
  {
    deviceId: '38568fd6-a225-4afc-83b3-5e6d0b50a2c0',
    make: 'Hyundai',
    model: 'SANTA FE',
    yearFrom: 2021,
    type: 'PHEV',
    status: 'BETA ELIGIBLE',
  },
  {
    deviceId: 'c5689cc5-d1b8-4102-aa2c-7b94aa25c31d',
    make: 'Hyundai',
    model: 'TUCSON',
    yearFrom: 2022,
    type: 'PHEV',
    status: 'BETA ELIGIBLE',
  },
  {
    deviceId: '0f09608c-5c17-45b2-a4f1-e12b6990a403',
    make: 'Jaguar',
    model: 'E-PACE',
    yearFrom: 2019,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: 'e749067c-51e6-4e97-b570-6495a97539b0',
    make: 'Jaguar',
    model: 'F-PACE',
    yearFrom: 2017,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '363f525a-3439-412a-b169-8d3300b16246',
    make: 'Jaguar',
    model: 'I-PACE',
    yearFrom: 2019,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '52e6b1f6-397e-4e6d-8663-5a47d6bbdffa',
    make: 'Jaguar',
    model: 'XE',
    yearFrom: 2016,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '41792c07-60f4-4e44-90c0-7854683e9ccb',
    make: 'Jaguar',
    model: 'XF',
    yearFrom: 2017,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: 'c08d8815-48b9-4db7-b3b0-10d6f977d734',
    make: 'Kia',
    model: 'Ceed',
    yearFrom: 2020,
    type: 'PHEV',
    status: 'BETA ELIGIBLE',
  },
  {
    deviceId: '00a5c1f3-4bd5-4163-b4f5-e37fabd59da8',
    make: 'Kia',
    model: 'e-Niro',
    yearFrom: 2019,
    type: 'BEV',
    status: 'BETA ELIGIBLE',
  },
  {
    deviceId: 'fd3758df-911a-4fa1-9c92-af03ca787a58',
    make: 'Kia',
    model: 'e-Soul',
    yearFrom: 2015,
    type: 'BEV',
    status: 'BETA ELIGIBLE',
  },
  {
    deviceId: '17e9bb76-b69d-434a-bb22-05be90063019',
    make: 'Kia',
    model: 'EV3',
    yearFrom: 2025,
    type: 'BEV',
    status: 'BETA ELIGIBLE',
  },
  {
    deviceId: '35048864-20fb-423b-a16d-adddae6fa5b6',
    make: 'Kia',
    model: 'EV6',
    yearFrom: 2022,
    type: 'BEV',
    status: 'BETA ELIGIBLE',
  },
  {
    deviceId: 'a3f8a4ad-bdd0-4767-a475-5b0e227418c4',
    make: 'Kia',
    model: 'EV9',
    yearFrom: 2024,
    type: 'BEV',
    status: 'BETA ELIGIBLE',
  },
  {
    deviceId: 'a4e5f215-e06c-4d97-8bdf-49882e7daf3b',
    make: 'Kia',
    model: 'Niro',
    yearFrom: 2018,
    type: 'PHEV',
    status: 'BETA ELIGIBLE',
  },
  {
    deviceId: '5e21bba1-fb4d-42ff-8fe8-2f14976e1ae6',
    make: 'Kia',
    model: 'Sorento',
    yearFrom: 2021,
    type: 'PHEV',
    status: 'BETA ELIGIBLE',
  },
  {
    deviceId: 'eb423051-0de6-4d44-a660-40192a278937',
    make: 'Kia',
    model: 'Sportage',
    yearFrom: 2022,
    type: 'PHEV',
    status: 'BETA ELIGIBLE',
  },
  {
    deviceId: '11e6bf4d-4fa1-4771-919e-11a4fe3fd81b',
    make: 'Kia',
    model: 'XCeed',
    yearFrom: 2020,
    type: 'PHEV',
    status: 'BETA ELIGIBLE',
  },
  {
    deviceId: '7fa5d9b6-4e64-491e-a2b4-22727bc89451',
    make: 'Land Rover',
    model: 'Defender',
    yearFrom: 2019,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: 'c7525c2f-5d53-4139-98bf-71b5c2212afe',
    make: 'Land Rover',
    model: 'Discovery',
    yearFrom: 2019,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '351d681f-6ee4-4cf8-94a9-fb1d1fc2020a',
    make: 'Land Rover',
    model: 'Range Rover',
    yearFrom: 2019,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '9e4a2143-cbbd-47e6-afc4-ca4114a992b1',
    make: 'Lexus',
    model: 'RX',
    yearFrom: 2022,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '8d4578a5-b314-492c-b7b1-3d6e38c31b9f',
    make: 'Lexus',
    model: 'RX',
    yearFrom: 2022,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '701a59bc-45d1-4dbc-81b2-3ee3c411d29a',
    make: 'Lexus',
    model: 'RZ',
    yearFrom: 2022,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '58f54190-b921-454c-b3f4-7f0fe2faffba',
    make: 'Lexus',
    model: 'UX',
    yearFrom: 2022,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '91d8deeb-434e-4936-bd46-01351b049be0',
    make: 'Mercedes',
    model: 'A',
    yearFrom: 2020,
    type: 'PHEV',
    status: 'BETA ELIGIBLE',
  },
  {
    deviceId: '7801b4c1-24e4-4730-911d-6d6770072719',
    make: 'Mercedes',
    model: 'B',
    yearFrom: 2021,
    type: 'BEV',
    status: 'BETA ELIGIBLE',
  },
  {
    deviceId: '156951ad-895a-434f-883b-64164912ac77',
    make: 'Mercedes',
    model: 'B Hybrid',
    yearFrom: 2021,
    type: 'PHEV',
    status: 'BETA ELIGIBLE',
  },
  {
    deviceId: '6acbdfd6-29c1-4759-a4a5-a475094c47a1',
    make: 'Mercedes',
    model: 'C',
    yearFrom: 2021,
    type: 'PHEV',
    status: 'BETA ELIGIBLE',
  },
  {
    deviceId: '8c7a4752-8926-4e75-99c2-9a75321a1789',
    make: 'Mercedes',
    model: 'CLA',
    yearFrom: 2021,
    type: 'PHEV',
    status: 'BETA ELIGIBLE',
  },
  {
    deviceId: 'aa2699a0-826c-47b4-858c-c10f40008c04',
    make: 'Mercedes',
    model: 'E',
    yearFrom: 2016,
    type: 'PHEV',
    status: 'BETA ELIGIBLE',
  },
  {
    deviceId: '11f668b8-e5b9-492d-b61b-9c568ef67cea',
    make: 'Mercedes',
    model: 'eCitan',
    yearFrom: 2023,
    type: 'BEV',
    status: 'BETA ELIGIBLE',
  },
  {
    deviceId: 'd798320c-76ce-4a0e-b548-5851feeedb3b',
    make: 'Mercedes',
    model: 'EQA',
    yearFrom: 2021,
    type: 'BEV',
    status: 'BETA ELIGIBLE',
  },
  {
    deviceId: '5f8a60e1-4462-41c9-aa81-9091a4a1a7f8',
    make: 'Mercedes',
    model: 'EQB',
    yearFrom: 2021,
    type: 'BEV',
    status: 'BETA ELIGIBLE',
  },
  {
    deviceId: '76a338bd-5dea-43c8-b821-e0df3fbe2bae',
    make: 'Mercedes',
    model: 'EQC',
    yearFrom: 2019,
    type: 'BEV',
    status: 'BETA ELIGIBLE',
  },
  {
    deviceId: '6d503055-cfac-4cdc-9223-a94879032586',
    make: 'Mercedes',
    model: 'EQE',
    yearFrom: 2021,
    type: 'BEV',
    status: 'BETA ELIGIBLE',
  },
  {
    deviceId: '782260ab-233c-4fa3-9b17-514ed5a262af',
    make: 'Mercedes',
    model: 'EQS',
    yearFrom: 2021,
    type: 'BEV',
    status: 'BETA ELIGIBLE',
  },
  {
    deviceId: 'fdcaea90-e0db-42af-9a79-a2116f3eb722',
    make: 'Mercedes',
    model: 'EQV',
    yearFrom: 2021,
    type: 'BEV',
    status: 'BETA ELIGIBLE',
  },
  {
    deviceId: 'e36666fd-1e18-473b-84f5-82635ec74952',
    make: 'Mercedes',
    model: 'eSprinter',
    yearFrom: 2021,
    type: 'BEV',
    status: 'BETA ELIGIBLE',
  },
  {
    deviceId: 'b673d4c9-c651-4195-8b7e-f9beb47878e5',
    make: 'Mercedes',
    model: 'eVito',
    yearFrom: 2001,
    type: 'BEV',
    status: 'BETA ELIGIBLE',
  },
  {
    deviceId: '5cdc28ec-c5a8-4a2f-ade0-c3c81ab70c22',
    make: 'Mercedes',
    model: 'G',
    yearFrom: 2025,
    type: 'BEV',
    status: 'BETA ELIGIBLE',
  },
  {
    deviceId: '479df381-ccd5-40a1-8ed1-bab2fa698d34',
    make: 'Mercedes',
    model: 'GLA',
    yearFrom: 2020,
    type: 'PHEV',
    status: 'BETA ELIGIBLE',
  },
  {
    deviceId: 'e91e5705-62b7-423c-80b9-b2ee93532207',
    make: 'Mercedes',
    model: 'GLC',
    yearFrom: 2021,
    type: 'PHEV',
    status: 'BETA ELIGIBLE',
  },
  {
    deviceId: 'efcb87ca-2957-4d3f-a517-fb67c376e27f',
    make: 'Mercedes',
    model: 'GLE',
    yearFrom: 2006,
    type: 'PHEV',
    status: 'BETA ELIGIBLE',
  },
  {
    deviceId: '25808c5f-3ada-4ed6-9c7f-5e19029070af',
    make: 'Mercedes',
    model: 'S',
    yearFrom: 2021,
    type: 'PHEV',
    status: 'BETA ELIGIBLE',
  },
  {
    deviceId: '825c3d0d-9a10-4b4b-9fa5-b0dddc4f6b23',
    make: 'MINI',
    model: 'Aceman',
    yearFrom: 2024,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: 'a18d3a80-410c-47e7-8be3-3e2fc69a1c00',
    make: 'MINI',
    model: 'Cooper',
    yearFrom: 2019,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '60eb661c-fd9f-48e0-9ed0-f7217a228871',
    make: 'MINI',
    model: 'Cooper Hybrid',
    yearFrom: 2017,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: 'a6d93ac7-aeef-4d58-83d7-c389b5fbe0fb',
    make: 'MINI',
    model: 'Countryman',
    yearFrom: 2024,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '2a8e57f6-c2cc-4aad-8802-96274e4e5ba7',
    make: 'MINI',
    model: 'Countryman Hybrid',
    yearFrom: 2020,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '332d6d4c-cdaf-4304-90da-d1539b081165',
    make: 'Nio',
    model: 'EL6',
    yearFrom: 2023,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: 'ab2aa346-cca6-4273-9cda-9dbd58e3b22b',
    make: 'Nio',
    model: 'EL7',
    yearFrom: 2022,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: 'bd46c0a5-e2a4-46d1-b456-8ab4000da13b',
    make: 'Nio',
    model: 'ES8',
    yearFrom: 2021,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '6044e71e-43f9-446c-a478-f71b6025cb4d',
    make: 'Nio',
    model: 'ET5',
    yearFrom: 2023,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: 'e7bcbeaf-b710-4d71-b826-2acaa38c5949',
    make: 'Nio',
    model: 'ET7',
    yearFrom: 2022,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '601d1378-16fd-4340-a51a-afaed4f51003',
    make: 'Nissan',
    model: 'Ariya',
    yearFrom: 2021,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: 'ad0cae1d-8000-4ef5-8c92-1effed17701f',
    make: 'Nissan',
    model: 'E-NV200',
    yearFrom: 2018,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: 'ac0885e8-6d4e-4c63-ace1-be1275b04641',
    make: 'Nissan',
    model: 'Leaf',
    yearFrom: 2019,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: 'eeb76a25-05f0-48c3-8ed8-5ff70b55c3a9',
    make: 'Nissan',
    model: 'Leaf ZE1',
    yearFrom: 2018,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: 'd8a4b1cf-9206-4241-81ff-6f4c7e3d12c2',
    make: 'Nissan',
    model: 'Leaf ZE1-P',
    yearFrom: 2019,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: 'f9bc2c28-680d-4a1b-a5af-16a91f07a954',
    make: 'Nissan',
    model: 'TOWNSTAR',
    yearFrom: 2021,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: 'e6a1306d-a661-46f7-b7ee-52abbeb809e8',
    make: 'Opel',
    model: 'Astra',
    yearFrom: 2022,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '2344328d-2bbf-4b95-8d3d-c19c3703a2ed',
    make: 'Opel',
    model: 'Combo-e LIFE',
    yearFrom: 2022,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: 'b2e4c35d-5cd0-426f-8129-8e29d46750a8',
    make: 'Opel',
    model: 'COMBUSES VAN L2',
    yearFrom: 2021,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '49c69d3a-b887-49b9-9015-17cdc71e4614',
    make: 'Opel',
    model: 'COMBUSES VAN L3',
    yearFrom: 2021,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '69761f88-802a-4f44-8569-139b2953bcca',
    make: 'Opel',
    model: 'Corsa-e',
    yearFrom: 2020,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: 'cc049133-c3ce-42aa-ad81-8cb086242cd8',
    make: 'Opel',
    model: 'Grandland X',
    yearFrom: 2020,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '1fa9ab0a-2534-4afd-8aad-3a9da42d5c98',
    make: 'Opel',
    model: 'Mokka-e',
    yearFrom: 2021,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: 'd1239a79-f8a8-459e-b9f9-bf12eea3e53a',
    make: 'Opel',
    model: 'PANEL VAN L1',
    yearFrom: 2021,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '3fa79247-8a3f-4e71-bfa9-0e8987725538',
    make: 'Opel',
    model: 'Vivaro',
    yearFrom: 2021,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '4b0eea27-1b3a-4c9d-bc2f-6395f2a0a729',
    make: 'Opel',
    model: 'Zafira Life 20',
    yearFrom: 2021,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '198c3903-e8f1-4a2c-a468-10b44320e564',
    make: 'Peugeot',
    model: '508',
    yearFrom: 2019,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '1dc6b3be-415b-4e14-ac86-95a556d6aee6',
    make: 'Peugeot',
    model: '3008',
    yearFrom: 2018,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '7289a5ed-d3c2-4539-9871-529e75c6004b',
    make: 'Peugeot',
    model: '308 SW',
    yearFrom: 2022,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '73863564-89c4-439f-a42f-f1852e690e21',
    make: 'Peugeot',
    model: 'e-2008',
    yearFrom: 2020,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '4221c95d-91a7-4982-b704-d7fc5e71b916',
    make: 'Peugeot',
    model: 'e-208',
    yearFrom: 2019,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '5755cdd2-c3c3-4c73-a9c8-1f97f04717aa',
    make: 'Peugeot',
    model: 'e-208 GT',
    yearFrom: 2020,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '1fc7e42a-c661-4463-9a68-7834b95db3b7',
    make: 'Peugeot',
    model: 'e-RIFTER',
    yearFrom: 2021,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '6ff381d6-ae29-413a-a208-d7789aef0523',
    make: 'Peugeot',
    model: 'EXPERT',
    yearFrom: 2019,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '5abd6a95-726f-49e3-ba4b-0104d780d04d',
    make: 'Peugeot',
    model: 'PARTNER',
    yearFrom: 2021,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: 'ef78dac0-b506-4717-b28b-4f5721204b87',
    make: 'Peugeot',
    model: 'TRAVELLER',
    yearFrom: 2020,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '5cb8453e-787f-425e-8578-1d11e23179c4',
    make: 'Porsche',
    model: 'Cayenne',
    yearFrom: 2015,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: 'cea353aa-d8fe-4468-a09e-968d95aa2ff4',
    make: 'Porsche',
    model: 'Macan',
    yearFrom: 2024,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: 'f82cea40-af69-40f0-bfa5-78a5ad7997dc',
    make: 'Porsche',
    model: 'Macan 4',
    yearFrom: 2024,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: 'b3aa4832-0748-442e-b673-c3b86a825899',
    make: 'Porsche',
    model: 'Panamera',
    yearFrom: 2014,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '0f4afa15-76ee-401e-a9f5-3c6241c03d21',
    make: 'Porsche',
    model: 'Taycan',
    yearFrom: 2020,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '9faeaa87-1f0b-475e-9910-11aafa9df826',
    make: 'Renault',
    model: 'CAPTUR II',
    yearFrom: 2020,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '57946e1e-8b9b-4532-ac09-5d9e427092fd',
    make: 'Renault',
    model: 'KANGOO II',
    yearFrom: 2013,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '7297ff6f-35a1-4dfd-b8e3-09c5eaee8ee5',
    make: 'Renault',
    model: 'KANGOO III',
    yearFrom: 2022,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '478cc559-a63a-458e-adfc-bee43ef821b2',
    make: 'Renault',
    model: 'MASTER III',
    yearFrom: 2020,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '0ff9b363-413b-4b9f-a84c-26112b5bfd5d',
    make: 'Renault',
    model: 'MEGANE',
    yearFrom: 2021,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '4d97511f-e338-43ee-bc7f-3f7db4e13ac3',
    make: 'Renault',
    model: 'MEGANE E-TECH',
    yearFrom: 2021,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '9dd4c788-9397-4bd0-b07e-8faeda092f59',
    make: 'Renault',
    model: 'MEGANE IV',
    yearFrom: 2020,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '18617124-6af1-4f3c-a5e6-214777767741',
    make: 'Renault',
    model: 'RAFALE',
    yearFrom: 2024,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: 'a8e4b1f7-34d9-48d5-b134-9ba3b30c3107',
    make: 'Renault',
    model: 'RENAULT 5',
    yearFrom: 2024,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '6d08a530-4694-4e80-9963-c8302e2bb8fb',
    make: 'Renault',
    model: 'SCENIC',
    yearFrom: 2024,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '2e7ca8f7-20b4-456e-80f9-f577ab869336',
    make: 'Renault',
    model: 'SCENIC E-TECH',
    yearFrom: 2023,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: 'd6c2856c-3731-4315-b96b-e72d2a327144',
    make: 'Renault',
    model: 'TWINGO III',
    yearFrom: 2020,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '8d126360-d106-4c9c-a151-ddac1e71b71d',
    make: 'Renault',
    model: 'TWIZY',
    yearFrom: 2012,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: 'fdcbc411-5668-4fa5-b60f-54568a7dc964',
    make: 'Renault',
    model: 'ZOE',
    yearFrom: 2019,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: 'd64e0633-04a1-469c-a3f3-65655f8e4814',
    make: 'Rivian',
    model: 'R1S',
    yearFrom: 2022,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '47caf186-c786-4612-9276-bd319055426f',
    make: 'Rivian',
    model: 'R1T',
    yearFrom: 2022,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: 'c3ef428a-c3b1-4679-a6e3-d5598b30b896',
    make: 'Seat',
    model: 'Leon',
    yearFrom: 2021,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '71a943fb-adf6-40b5-8d61-8d25541bb339',
    make: 'Seat',
    model: 'Mii',
    yearFrom: 2020,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '8477d677-3e8c-4875-a9b1-e436a310fa04',
    make: 'Seat',
    model: 'Tarraco',
    yearFrom: 2021,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '22546a39-ac6e-4982-ba34-a6e4ef0f9a36',
    make: 'Skoda',
    model: 'CitigoE iV',
    yearFrom: 2019,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: 'f1105288-e84e-4921-9257-84ab12862019',
    make: 'Skoda',
    model: 'Elroq',
    yearFrom: 2024,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: 'a03c8879-5743-4776-b122-bc7e4ce0f51b',
    make: 'Skoda',
    model: 'Enyaq',
    yearFrom: 2020,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '40a6dfba-aa4e-49c4-ad07-8cbe009ed92a',
    make: 'Skoda',
    model: 'Kodiaq',
    yearFrom: 2024,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '8c17185a-1f10-40dc-85b3-fb2cee788491',
    make: 'Skoda',
    model: 'Octavia',
    yearFrom: 2020,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: 'bf03f4be-e0a1-4929-bf62-00c0a2529b27',
    make: 'Skoda',
    model: 'Superb',
    yearFrom: 2019,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '728832db-038b-48dc-a0d2-94307f69dab5',
    make: 'Tesla',
    model: 'Cybertruck',
    yearFrom: 2024,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '97b09fc3-359d-4274-947a-f9a4b2b67467',
    make: 'Tesla',
    model: 'Model 3',
    yearFrom: 2017,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '687909cc-d4a2-447e-bf62-7ade1d57be7f',
    make: 'Tesla',
    model: 'Model S',
    yearFrom: 2012,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '8ba82a02-3cae-4e37-a512-d194af6c755e',
    make: 'Tesla',
    model: 'Model X',
    yearFrom: 2016,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '3f9ca2fe-6e89-4ff4-8771-51dc94552489',
    make: 'Tesla',
    model: 'Model Y',
    yearFrom: 2020,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '1031246e-5df3-4ada-9a1b-dfcf58aa86bb',
    make: 'Toyota',
    model: 'Auris',
    yearFrom: 2023,
    type: 'PHEV',
    status: 'BETA ELIGIBLE',
  },
  {
    deviceId: 'd08c0c96-ad3c-4037-a557-0e3029868058',
    make: 'Toyota',
    model: 'bZ4X',
    yearFrom: 2022,
    type: 'BEV',
    status: 'BETA ELIGIBLE',
  },
  {
    deviceId: 'f1ecf3f2-ca89-4d19-a847-ca7b38bad302',
    make: 'Toyota',
    model: 'C-HR',
    yearFrom: 2023,
    type: 'PHEV',
    status: 'BETA ELIGIBLE',
  },
  {
    deviceId: 'b5c25961-28e3-4642-93b3-0be6d272f9da',
    make: 'Toyota',
    model: 'Prius',
    yearFrom: 2012,
    type: 'PHEV',
    status: 'BETA ELIGIBLE',
  },
  {
    deviceId: '2f612724-716e-460a-abca-8b5546faeaea',
    make: 'Toyota',
    model: 'Proace',
    yearFrom: 2023,
    type: 'BEV',
    status: 'BETA ELIGIBLE',
  },
  {
    deviceId: '96a046b9-0b8b-4e15-b472-330825809601',
    make: 'Toyota',
    model: 'Proace City EV',
    yearFrom: 2021,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '6b40dee1-16d3-4409-973c-f8ac091cd14e',
    make: 'Toyota',
    model: 'Proace City Verso EV',
    yearFrom: 2022,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '3722eecb-1a6b-4f03-93be-432d51a920e9',
    make: 'Toyota',
    model: 'Proace EV',
    yearFrom: 2020,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '733571c6-d9bb-449e-90f5-dc0b56386bd0',
    make: 'Toyota',
    model: 'Proace Verso EV',
    yearFrom: 2021,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '52d6c89f-6226-4a35-ba6a-e4547817a3ba',
    make: 'Toyota',
    model: 'RAV4',
    yearFrom: 2020,
    type: 'PHEV',
    status: 'BETA ELIGIBLE',
  },
  {
    deviceId: '7140c9dc-fc84-4a4f-9441-e14644d5d535',
    make: 'Vauxhall',
    model: 'Combo-e LIFE',
    yearFrom: 2022,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: 'd9df62d8-f958-430d-8492-7dd5000856ce',
    make: 'Vauxhall',
    model: 'Corsa-e',
    yearFrom: 2020,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '244f4b20-d8e8-4317-b2a8-c196c0de3c05',
    make: 'Vauxhall',
    model: 'Grandland X',
    yearFrom: 2020,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '987183be-e6ac-471d-ae66-e0f32142a2a7',
    make: 'Vauxhall',
    model: 'Mokka-e',
    yearFrom: 2021,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: 'f1560c49-b388-4ad6-817d-da662739fede',
    make: 'Vauxhall',
    model: 'Vivaro Life',
    yearFrom: 2021,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '746715c7-e600-45e3-ac4d-1971fcbfdce7',
    make: 'Volkswagen',
    model: 'Arteon',
    yearFrom: 2018,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '00d861f5-66fb-4746-959a-17e5952114ed',
    make: 'Volkswagen',
    model: 'e-crafter',
    yearFrom: 2021,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: 'f515e2c3-1aac-4dd2-b575-d10488a0f550',
    make: 'Volkswagen',
    model: 'e-golf',
    yearFrom: 2014,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '630fbb1a-d85f-499e-83d9-7e9b0ce0067e',
    make: 'Volkswagen',
    model: 'e-Transporter',
    yearFrom: 2022,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '8e3ed1e9-cd61-45ec-bd3c-a4a1dae6bc13',
    make: 'Volkswagen',
    model: 'e-up',
    yearFrom: 2014,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '863bb351-ad91-4292-9f1e-f8240bd37c3e',
    make: 'Volkswagen',
    model: 'Golf',
    yearFrom: 2015,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: 'dda99371-f634-41bf-89be-6781c334df4c',
    make: 'Volkswagen',
    model: 'ID. Buzz',
    yearFrom: 2023,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: 'f254dc33-9bc4-4a0f-9656-83da96d3b885',
    make: 'Volkswagen',
    model: 'ID. Buzz Cargo',
    yearFrom: 2023,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '52d56b3c-bbec-4fdc-9edc-60bb9eb2e1d1',
    make: 'Volkswagen',
    model: 'ID.3',
    yearFrom: 2020,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '19d78383-4dde-4179-b8b7-e5b91386d7d0',
    make: 'Volkswagen',
    model: 'ID.4',
    yearFrom: 2021,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '26b32549-32c0-4f45-a2c1-31ad20c58dcd',
    make: 'Volkswagen',
    model: 'ID.5',
    yearFrom: 2022,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '38f8a0bd-710e-4856-b4f9-4189c7fff450',
    make: 'Volkswagen',
    model: 'ID.7',
    yearFrom: 2024,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: 'd9207784-40f6-45c9-b7ea-357c3e0d716f',
    make: 'Volkswagen',
    model: 'Multivan',
    yearFrom: 2017,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '48402d67-d31e-41d6-b1bf-96bdde574f90',
    make: 'Volkswagen',
    model: 'Passat',
    yearFrom: 2015,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '9f67d6a1-72bb-4592-967b-8beccec28601',
    make: 'Volkswagen',
    model: 'Tiguan',
    yearFrom: 2016,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '5c5ba9b5-23e2-4d01-9a4f-1a79b81292ac',
    make: 'Volkswagen',
    model: 'Touareg',
    yearFrom: 2018,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '158d587e-7b7a-414b-b7c6-9a17051e95cd',
    make: 'Volkswagen',
    model: 'Transporter 7',
    yearFrom: 2022,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '799ece88-969a-42eb-b749-e6a91025d403',
    make: 'Volvo',
    model: 'C40 Recharge Pure Electric',
    years: [2021, 2022, 2023, 2024],
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: 'e49bd33a-abd1-41a5-a381-2255610c7228',
    make: 'Volvo',
    model: 'EC40',
    yearFrom: 2024,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '3302626c-f280-4403-a380-806c2d9f573b',
    make: 'Volvo',
    model: 'EX30',
    yearFrom: 2024,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '0cb742c4-4913-4e11-ad10-f6b441d0a09c',
    make: 'Volvo',
    model: 'EX40',
    yearFrom: 2024,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '841403fa-73a4-4dbe-aac0-5df9af0ae1dc',
    make: 'Volvo',
    model: 'EX90',
    yearFrom: 2024,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '0fb28431-a99e-4851-8ede-4eee721935df',
    make: 'Volvo',
    model: 'S60',
    years: [2019, 2020, 2021, 2022],
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '0035762f-d640-4f6f-ad25-83745453dfa0',
    make: 'Volvo',
    model: 'S90',
    years: [2018, 2019, 2020, 2021],
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '02e9bcd8-a656-44c2-8fe5-1053d20d0e46',
    make: 'Volvo',
    model: 'V60',
    years: [2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022],
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: 'c9ae74ba-ad9e-43e0-bcbd-9ca7cd89dca1',
    make: 'Volvo',
    model: 'V90',
    years: [2018, 2019, 2020, 2021],
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '818a1c42-746c-489c-a294-493c859629aa',
    make: 'Volvo',
    model: 'XC40',
    years: [2019, 2020, 2021, 2022],
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '97f9abee-b41d-475b-a252-649be85c12b5',
    make: 'Volvo',
    model: 'XC40 Recharge Pure Electric',
    years: [2021, 2022, 2023, 2024],
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '9769f5ea-fe81-4ad1-a24f-08b39352a207',
    make: 'Volvo',
    model: 'XC60',
    years: [2018, 2019, 2020, 2021],
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: 'df8c0275-86e9-4ebb-8f32-8f674b996a53',
    make: 'Volvo',
    model: 'XC90',
    years: [2016, 2017, 2018, 2019, 2020, 2021, 2022],
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '9f0f4626-1e07-44f7-a651-c1a981558e20',
    make: 'XPENG',
    model: 'G3',
    yearFrom: 2024,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '9deda936-0288-48b9-8e37-c5e5321d5dac',
    make: 'XPENG',
    model: 'G3i',
    yearFrom: 2023,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: 'ca8e89e6-b0f0-4629-98e1-865ff3e49aa0',
    make: 'XPENG',
    model: 'G9',
    yearFrom: 2023,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '66537ec2-10af-433f-9670-1815542faa23',
    make: 'XPENG',
    model: 'P5',
    yearFrom: 2023,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: 'e1920ddb-6eb2-44d6-804d-4e832b86c521',
    make: 'XPENG',
    model: 'P7',
    yearFrom: 2023,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    deviceId: '33ca5769-7098-4816-9ae6-b60b119dfe8b',
    make: 'XPENG',
    model: 'P7i',
    yearFrom: 2024,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
];

// Vehicles that the Volvo partnership (where they integrate via the volvo app) apply to
export const volvoPartnershipVehicles: Vehicle[] = [
  {
    deviceId: '799ece88-969a-42eb-b749-e6a91025d403',
    make: 'Volvo',
    model: 'C40 Recharge Pure Electric',
    years: [2021, 2022, 2023, 2024],
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: 'e49bd33a-abd1-41a5-a381-2255610c7228',
    make: 'Volvo',
    model: 'EC40',
    yearFrom: 2024,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '0cb742c4-4913-4e11-ad10-f6b441d0a09c',
    make: 'Volvo',
    model: 'EX40',
    yearFrom: 2024,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    deviceId: '97f9abee-b41d-475b-a252-649be85c12b5',
    make: 'Volvo',
    model: 'XC40 Recharge Pure Electric',
    years: [2021, 2022, 2023, 2024],
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
];

export type VolvoPartnershipFlags = {
  isPayAsYouDriveVolvoEnabled: boolean;
};
export const getVolvoPartnershipVehicles = (
  flags: VolvoPartnershipFlags
): Vehicle[] => {
  if (flags.isPayAsYouDriveVolvoEnabled) {
    return volvoPartnershipVehicles;
  } else {
    return [];
  }
};

const NOT_LISTED = 'not_listed';

export const makes: Make[] = [
  ...new Set(vehicles.map(({ make }) => make)),
].sort((a, z) => a.localeCompare(z));

export const makeOptions = [
  { label: 'My EV make isn’t listed', value: NOT_LISTED },
  ...makes.map((make) => ({ label: make, value: make })),
];

export const getModels = (make: Make): Vehicle[] =>
  vehicles
    .filter((vehicle) => vehicle.make === make)
    .sort((a, z) => a.model.localeCompare(z.model));

export const modelOptions = (make: Make) => [
  { label: 'My EV model isn’t listed', value: NOT_LISTED },
  ...getModels(make).map(({ model, type, deviceId }) => ({
    value: deviceId,
    label: type === 'PHEV' ? `${model} (PHEV)` : model,
  })),
];

export const getVehicleInfo = (deviceId: string | null) =>
  vehicles.find((vehicle) => vehicle.deviceId === deviceId);

const maxSupportedYear = new Date().getFullYear() + 1;
const availableYears = Array.from(
  { length: maxSupportedYear - 2010 + 1 }, // Always start from 2010
  (_, i) => maxSupportedYear - i
);
export const yearOptions = availableYears.map((year, i, arr) => ({
  value: `${year}`,
  label: `${year}${i === arr.length - 1 ? ' or earlier' : ''}`,
}));

const eligibleStatuses = ['BETA ELIGIBLE', 'FULLY ELIGIBLE'];

export const isVehicleInList =
  (list: Vehicle[]) =>
  (make: Make | null, model: Model | null, year: number | null) =>
    list.some(
      (item) =>
        item.make === make &&
        item.model === model &&
        year !== null &&
        (item.yearFrom
          ? item.yearFrom <= year
          : item.years?.includes(year) ?? false)
    );

export const isVehicleEligible = (
  deviceId: string | null,
  year: number | null,
  flags: VolvoPartnershipFlags
): boolean => {
  if (!deviceId || !year) {
    return false;
  }
  return vehicles.concat(getVolvoPartnershipVehicles(flags)).some((vehicle) => {
    if (vehicle.deviceId !== deviceId) {
      return false;
    }
    const isEligibleYear = vehicle.years?.length
      ? vehicle.years.includes(year)
      : vehicle.yearFrom
      ? vehicle.yearFrom <= year
      : false;

    return isEligibleYear && eligibleStatuses.includes(vehicle.status);
  });
};

export const isNotListedSelected = (make: Make | Model | null) =>
  make === NOT_LISTED;

export const VEHICLE_ENQUIRY_LINK = 'https://vehicleenquiry.service.gov.uk/';

export const COMPATIBLE_EV_CHARGERS_LINK =
  'https://www.ovoenergy.com/electric-cars/charge-anytime-cars-and-chargers#chargers';

export enum PromoType {
  CAN_ADD_VOLVO_PROMO = 'canAddVolvoPromo',
  CAN_LINK_TO_VOLVO_PROMO = 'canLinkToVolvoPromo',
  CAN_LINK_TO_VW_PROMO = 'canLinkToVWPromo',
}

export const eligibleForPromo = (
  deviceId: string | null,
  year: number | null,
  smartChargerSelection: SmartChargers | null,
  hasSolarPanels: boolean,
  hasInternetAccess: boolean,
  flags: VolvoPartnershipFlags
): PromoType | boolean => {
  const partnershipVehicles = getVolvoPartnershipVehicles(flags);
  if (!deviceId || !year || !smartChargerSelection) {
    return false;
  }

  const { make, model } = partnershipVehicles.find(
    (vehicle) => vehicle.deviceId === deviceId
  ) || { make: null, model: null };

  if (make === 'Volvo') {
    if (
      partnershipVehicles.some((volvo) => {
        const isEligibleYear = volvo.years?.length
          ? volvo.years.includes(year)
          : volvo.yearFrom
          ? volvo.yearFrom <= year
          : false;

        return (
          volvo.make === make &&
          volvo.model === model &&
          isEligibleYear &&
          eligibleStatuses.includes(volvo.status)
        );
      })
    ) {
      if (!hasSolarPanels && hasInternetAccess) {
        return PromoType.CAN_ADD_VOLVO_PROMO;
      } else if (
        [
          SmartChargers.Indra,
          SmartChargers.Hypervolt,
          SmartChargers.Andersen,
        ].includes(smartChargerSelection) ||
        (!hasSolarPanels &&
          [SmartChargers.Ohme].includes(smartChargerSelection))
      ) {
        return PromoType.CAN_LINK_TO_VOLVO_PROMO;
      }
    }
  }
  return false;
};

export const eligibleForVWGPromo = (
  make: Make | null,
  model: Model | null,
  year: number | null
): PromoType | boolean => {
  if (!make || !model || !year) {
    return false;
  }
  if (VWGMakes.includes(make) && year >= 2024) {
    if (
      vehicles.some(
        (vw) => vw.make === make && vw.model === model && vw.type === 'BEV'
      )
    ) {
      return PromoType.CAN_LINK_TO_VW_PROMO;
    }
  }
  return false;
};

export const generateVehicleSelections = (
  vehicles: VehicleDevice[],
  isChargeAnytimeDevicesActive = false
) => {
  if (!isChargeAnytimeDevicesActive) {
    return {
      makeOptions: makeOptions,
      modelOptions: modelOptions,
    };
  }
  const makes = [
    ...new Set(vehicles.map(({ manufacturer }) => manufacturer)),
  ].sort((a, z) => a.localeCompare(z));
  const getModels = (manufacturer: VehicleDevice['manufacturer']) =>
    vehicles
      .filter((vehicle) => vehicle.manufacturer === manufacturer)
      .sort((a, z) => a.model.localeCompare(z.model));
  return {
    makeOptions: [
      { label: 'My EV make isn’t listed', value: NOT_LISTED },
      ...makes.map((make) => ({ label: make, value: make })),
    ],
    modelOptions: (manufacturer: VehicleDevice['manufacturer']) => [
      { label: 'My EV model isn’t listed', value: NOT_LISTED },
      ...getModels(manufacturer).map(({ model, deviceId, vehicleType }) => ({
        value: deviceId,
        label: vehicleType === 'PHEV' ? `${model} (PHEV)` : model,
      })),
    ],
  };
};
