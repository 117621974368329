import { CHARGE_ANYTIME_API_ROOT } from '@/src/constants/endpoints';
import { apiService } from '@/src/services/api-service';
import { ChargeAnytimeApiFetch } from '@monovo/ev-anytime';

export const fetchFn: ChargeAnytimeApiFetch = (uri, options) => {
  let callback;
  switch (options?.method) {
    case 'POST':
      callback = apiService.post;
      break;
    case 'GET':
    default:
      callback = apiService.get;
      break;
  }
  return callback({
    url: `${CHARGE_ANYTIME_API_ROOT}${uri}`,
    headers: {
      'X-Api-Key': process.env.NX_CHARGE_ANYTIME_API_KEY!,
    },
    ...options,
  });
};
