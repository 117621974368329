import React from 'react';

import { Margin, Row } from '@ovotech/nebula';

import { useEnergyInsightsHubQuery } from '@/src/api/use-energy-insights-hub-query';
import { PageWithNavigationAndSectionPadding } from '@/src/components/Pages/Pages';
import { energyInsightsStrings as strings } from '@/src/pages/EnergyInsights/strings';
import { EnergyInsightsHubResponse } from '@/src/types/Response';

import {
  EnergyInsightsTermsLink,
  GenericPageError,
  PageHeaderWithCarbonIntensity,
} from '../../components';
import { CarbonComparison } from './Components/CarbonComparison/CarbonComparison';
import CarbonIntensityWidget from './Components/CarbonIntensityWidget/CarbonIntensityWidget';
import { HalfHourlyOptIn } from './Components/HalfHourlyOptIn/HalfHourlyOptIn';
import NonSmartTopTips from './Components/NonSmartTopTips/NonSmartTopTips';
import { HomeProfileWidgetHub } from './Components/HomeProfileWidget.Hub';
import { useHubHomeProfileWidget } from './useHubHomeProfileWidget';
import { EnergyInsightsPageName, CarbonIntensityLevel } from '../../types';
import { BreakdownWidgetV2 } from './Components/BreakdownWidget/BreakdownWidgetV2';

export const HubPage = () => (
  <PageWithNavigationAndSectionPadding
    title={strings.productPageHeading}
    analytics={{ name: EnergyInsightsPageName.Hub }}
  >
    <Content />
    <Margin bottom={6} />
    <EnergyInsightsTermsLink />
  </PageWithNavigationAndSectionPadding>
);

function Content() {
  const { data, error } = useEnergyInsightsHubQuery();

  if (error) {
    return <HubError />;
  }

  if (!data) {
    return <NoData />;
  }

  return <Hub {...data} />;
}

export function Hub({
  hasSmartMeter,
  homeProfileWidget,
  halfHourlyReads,
  carbonFigures,
  breakdownWidgetV2,
  hasBreakdownData,
  currentIntensity,
  carbonGreentime,
}: EnergyInsightsHubResponse) {
  const { widgetPosition } = useHubHomeProfileWidget(homeProfileWidget);

  return (
    <>
      <Header carbonIntensityLevel={currentIntensity?.index} />
      {homeProfileWidget && widgetPosition === 'top' && (
        <HomeProfileWidgetHub
          pagePosition="top"
          homeProfileWidget={homeProfileWidget}
        />
      )}
      <CarbonComparison carbonFigures={carbonFigures} />
      <HalfHourlyOptIn
        halfHourlyReads={halfHourlyReads}
        hasSmartMeter={hasSmartMeter}
      />
      {!hasBreakdownData ? <NonSmartTopTips /> : null}
      <Row isNested={true}>
        {breakdownWidgetV2 && <BreakdownWidgetV2 {...breakdownWidgetV2} />}
        <CarbonIntensityWidget
          greentime={carbonGreentime?.greentime}
          currentIntensity={currentIntensity?.index}
        />
      </Row>
      {homeProfileWidget && widgetPosition === 'bottom' && (
        <HomeProfileWidgetHub
          pagePosition="bottom"
          homeProfileWidget={homeProfileWidget}
        />
      )}
    </>
  );
}

function HubError() {
  return (
    <>
      <Header />
      <Margin bottom={4} />
      <GenericPageError />
    </>
  );
}

function NoData() {
  return (
    <>
      <Header />
    </>
  );
}

function Header({
  carbonIntensityLevel,
}: {
  carbonIntensityLevel?: CarbonIntensityLevel;
}) {
  return (
    <PageHeaderWithCarbonIntensity
      mainTitle={strings.productPageHeading}
      subTitle="Free insights and simple energy-saving advice"
      currentIntensity={carbonIntensityLevel}
    />
  );
}
