import { useAccountContext } from '@ovotech/ui-tools';
import { useQuery } from 'react-query';
import { createProductCatalogueEndpointV2 } from '@/src/constants/endpoints';
import { ProductCatalogueV2 } from '@/src/types/Response';
import { apiService } from '@/src/services/api-service';

export const useProductCatalogueQueryV2 = (promoCode?: string) => {
  const { accountId } = useAccountContext();

  return useQuery(
    ['orion-product-catalogue-v2', accountId],
    () =>
      apiService.get<ProductCatalogueV2>({
        url: createProductCatalogueEndpointV2(accountId ?? '', promoCode),
      }),
    {
      enabled: !!accountId,
    },
  );
};
