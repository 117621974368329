import { OurPartnersBanner } from '@/src/components/Banners/OurPartnersBanner';
import SetMeterReadsBanner from '@/src/components/Banners/SetMeterReadsBanner';
import { ENABLE_PARTNERS } from '@/src/constants/feature-flags';
import useWindowDimensions from '@/src/utils/hooks/useWindowDimensions';
import { useFeature } from '@/src/utils/optimizely';
import { ProfileView } from '@ovotech/energy-cx';
import { Stack } from '@ovotech/nebula';
import React from 'react';

export const ProfileStack = () => {
  const { width } = useWindowDimensions({ debounceDelay: 0 });
  const isMobile = width < 576;
  const [isEnabled] = useFeature(ENABLE_PARTNERS);

  return (
    <Stack spaceBetween={isMobile ? 4 : 8}>
      <ProfileView />
      <SetMeterReadsBanner />
      {isEnabled && <OurPartnersBanner />}
    </Stack>
  );
};
