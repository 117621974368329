import { useChargeAnytimeContext } from '../lib/charge-anytime-context';
import { useChargeAnytimeDevices } from './useChargeAnytimeDevices';
import { generateChargerSelections } from '../lib/anytime-chargers';

export const useChargerOptions = () => {
  const { chargeAnytimeApi } = useChargeAnytimeContext();
  const { devicesEndpointEnabled } = chargeAnytimeApi.config;
  const { data, isLoading, isError } = useChargeAnytimeDevices('chargers');
  return {
    chargers: generateChargerSelections(
      data?.devices ?? [],
      devicesEndpointEnabled
    ),
    isLoading,
    isError,
  };
};
