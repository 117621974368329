import { ROUTE_DD_CALCULATOR } from '@/src/constants/routes';
import { useFeature } from '@optimizely/react-sdk';
import { useMemo } from 'react';

const useTranslations = () => {
  const [changeDirectDebitRefundMonths, variables] = useFeature(
    'change_direct_debit_refund_months',
  );

  const directDebitRefundMonthsString = changeDirectDebitRefundMonths
    ? `${variables.direct_debit_refund_months_amount} months’ Direct Debit payments`
    : `${variables.direct_debit_refund_months_amount} month’s Direct Debit payment`;

  const directDebitRefundMonths = changeDirectDebitRefundMonths
    ? `${variables.direct_debit_refund_months_amount} months’`
    : `${variables.direct_debit_refund_months_amount} month’s`;

  const translations = useMemo(() => {
    return {
      en: {
        translation: {
          common: {
            retailer: 'OVO Energy',
          },
          profile: {
            editMarketingPreferencesIntro: `Be first to hear about fantastic new products, services, rewards and offers from OVO Energy. Get invited to exclusive
        events and competitions. Discover how to save £££s with our energy-saving tips.`,
          },
          payments: {
            setupDirectDebitBody: `<P>First set a date for us to collect your monthly payment – then choose how much you'd like to pay. If we think this amount won't cover the energy you're using we'll get in touch and ask you to adjust it.</P><P>Need help working out the right monthly amount? <TextLink href="${ROUTE_DD_CALCULATOR}">Check out the Direct Debit calculator</TextLink>.</P>`,
            cardPaymentHeading: `Make a payment`,
          },
          refund: {
            applyBody: `
            <P>
              Your current balance is <Strong>{{currentBalance}}. </Strong>
              We recommend leaving enough credit to cover ${directDebitRefundMonthsString}.
            </P>
            <P>
              If you have less credit than this but you'd still like to request a refund, please give our team a call on 0330 303 5063 from Monday to Friday, 8am till 6pm.
            </P>
            <P>
              You can also reach us via online chat (Monday to Friday, 8am to 6pm, and Saturday, 9am to 1pm).
            </P>
            <P>
              Once your refund goes through, we'll send it to your bank account
              within <ProcessingDays>{{refundProcessingDays}} working days</ProcessingDays>.
            </P>
            `,
            balanceWouldNotExceedDirectDebitError: `
            <P>
              We recommend leaving enough credit to cover ${directDebitRefundMonthsString}.
            </P>
            <P>
              If you have less credit than this but you'd still like to request a refund, please give our team a call on 0330 303 5063 from Monday to Friday, 8am till 6pm.
            </P>
            <P>
              You can also reach us via online chat (Monday to Friday, 8am to 6pm, and Saturday, 9am to 1pm).
            </P>`,
            ineligibleReasonOneDD: `Have enough credit to cover your bills – we recommend keeping at least ${directDebitRefundMonths} worth of Direct Debit payments as credit.`,
          },
          moveOut: {
            pageTitle: 'Moving home',
            moveOutHeading: 'Moving home',
          },
          moveOutConfirmation: {
            plansPromoCta: 'Move In', // TODO: RTN-1757 - Blame Aaron. Used by `Get a Quote` button on the confirmation page. Makes it clearer it's for moving in
          },
          meterReadings: {
            errorsServerTooLow:
              '<P>We expected this reading to be higher - please check again before continuing.</P>',
            errorsServerTooLowMultiRate:
              '<P>We expected this reading to be higher - please check again before continuing. Make sure you enter both readings in the right boxes.</P>',
          },
        },
      },
    };
  }, [directDebitRefundMonthsString, directDebitRefundMonths]);
  return translations;
};

export { useTranslations };
