import { P, RadioField, CheckboxField } from '@ovotech/nebula';
import React, { useEffect } from 'react';

import Checkbox from '../../Form/CheckboxButton';
import RadioButton from '../../Form/RadioButton';
import { HomeProfileFormWizardStep } from './HomeProfileFormWizardStep';
import { homeProfileConfig } from './config';

import { ENERGY_INSIGHTS } from '@/src/constants/analytics';

import AnalyticsService from '@/src/services/analyticsService';

type HomeProfileWizardProps = {
  step: number;
  formError: boolean;
};

const [step1, step2, step3, step4, step5, step6, step7, step8] =
  homeProfileConfig.allQuestionSteps;

export const HomeProfileFormWizard = ({
  step,
  formError,
}: HomeProfileWizardProps) => {
  useEffect(() => {
    if (Number.isInteger(step)) {
      AnalyticsService.dispatch(ENERGY_INSIGHTS.HOMES_LIKE_YOURS_ + step);
    }
  }, [step]);

  return (
    <>
      <HomeProfileFormWizardStep dataTestId={step1.testId} visible={step === 1}>
        <fieldset>
          <RadioField
            name={step1.fieldSetName}
            optional={step1.optional}
            label={step1.label}
            error={formError ? 'This is a required question' : null}
          >
            <P>{step1.description}</P>
            <RadioButton name="propertyType" value="Flat" label="Flat" />
            <RadioButton
              name="propertyType"
              value="Detached"
              label="Detached"
            />
            <RadioButton
              name="propertyType"
              value="SemiDetached"
              label="Semi-Detached"
            />
            <RadioButton
              name="propertyType"
              value="MidTerrace"
              label="Mid Terrace"
            />
            <RadioButton
              name="propertyType"
              value="EndTerrace"
              label="End Terrace"
            />
            <RadioButton
              name="propertyType"
              value="Bungalow"
              label="Bungalow"
            />
          </RadioField>
        </fieldset>
      </HomeProfileFormWizardStep>

      <HomeProfileFormWizardStep dataTestId={step2.testId} visible={step === 2}>
        <fieldset>
          <RadioField
            name={step2.fieldSetName}
            optional={step2.optional}
            label={step2.label}
            error={formError ? 'This is a required question' : null}
          >
            <RadioButton name="numBedrooms" value="OneBedroom" label="1" />
            <RadioButton name="numBedrooms" value="TwoBedrooms" label="2" />
            <RadioButton name="numBedrooms" value="ThreeBedrooms" label="3" />
            <RadioButton name="numBedrooms" value="FourBedrooms" label="4" />
            <RadioButton
              name="numBedrooms"
              value="FiveOrMoreBedrooms"
              label="5+"
            />
          </RadioField>
        </fieldset>
      </HomeProfileFormWizardStep>

      <HomeProfileFormWizardStep dataTestId={step3.testId} visible={step === 3}>
        <fieldset>
          <RadioField
            name={step3.fieldSetName}
            optional={step3.optional}
            label={step3.label}
            error={formError ? 'This is a required question' : null}
          >
            <RadioButton name="numOccupants" value="OnePerson" label="1" />
            <RadioButton name="numOccupants" value="TwoPeople" label="2" />
            <RadioButton name="numOccupants" value="ThreePeople" label="3" />
            <RadioButton name="numOccupants" value="FourPeople" label="4" />
            <RadioButton
              name="numOccupants"
              value="FiveOrMorePeople"
              label="5+"
            />
          </RadioField>
        </fieldset>
      </HomeProfileFormWizardStep>
      <HomeProfileFormWizardStep dataTestId={step4.testId} visible={step === 4}>
        <fieldset>
          <RadioField
            name={step4.fieldSetName}
            optional={step4.optional}
            label={step4.label}
            style={{ width: '90%' }}
            error={formError ? 'This is a required question' : null}
          >
            <RadioButton
              name="averageHoursAtHome"
              value="ZeroToThree"
              label="0 to 3"
            />
            <RadioButton
              name="averageHoursAtHome"
              value="ThreeToSix"
              label="3 to 6"
            />
            <RadioButton
              name="averageHoursAtHome"
              value="SixToNine"
              label="6 to 9"
            />
            <RadioButton
              name="averageHoursAtHome"
              value="NineToTwelve"
              label="9 to 12"
            />
            <RadioButton
              name="averageHoursAtHome"
              value="TwelveOrMore"
              label="12+"
            />
          </RadioField>
        </fieldset>
      </HomeProfileFormWizardStep>
      <HomeProfileFormWizardStep dataTestId={step5.testId} visible={step === 5}>
        <fieldset>
          <RadioField
            name={step5.fieldSetName}
            optional={step5.optional}
            label={step5.label}
            error={formError ? 'This is a required question' : null}
          >
            <RadioButton
              name="homeHeat"
              value="GasHeating"
              label="Gas heating"
            />
            <RadioButton
              name="homeHeat"
              value="StorageHeating"
              label="Storage heating"
            />
            <RadioButton
              name="homeHeat"
              value="ElectricHeating"
              label="Electric heating"
            />
            <RadioButton name="homeHeat" value="HeatPump" label="Heat pump" />
            <RadioButton name="homeHeat" value="Other" label="Other" />
          </RadioField>
        </fieldset>
      </HomeProfileFormWizardStep>
      <HomeProfileFormWizardStep dataTestId={step6.testId} visible={step === 6}>
        <fieldset>
          <CheckboxField
            name={step6.fieldSetName}
            optional={step6.optional}
            label={step6.label}
            style={{ width: '90%' }}
          >
            <P>{step6.description}</P>
            <Checkbox
              name="cleaningAppliances"
              value="WashingMachine"
              label="Washing machine"
            />
            <Checkbox
              name="cleaningAppliances"
              value="TumbleDryer"
              label="Tumble dryer"
            />
            <Checkbox
              name="cleaningAppliances"
              value="Dishwasher"
              label="Dishwasher"
            />
            <Checkbox
              name="kitchenAppliances"
              value="ElectricHob"
              label="Electric Hob"
            />
            <Checkbox name="kitchenAppliances" value="GasHob" label="Gas Hob" />
            <Checkbox
              name="kitchenAppliances"
              value="ElectricOven"
              label="Electric Oven"
            />
            <Checkbox
              name="kitchenAppliances"
              value="GasOven"
              label="Gas Oven"
            />
            <Checkbox
              name="kitchenAppliances"
              value="Microwave"
              label="Microwave"
            />
          </CheckboxField>
        </fieldset>
      </HomeProfileFormWizardStep>
      <HomeProfileFormWizardStep dataTestId={step7.testId} visible={step === 7}>
        <fieldset>
          <CheckboxField
            name={step7.fieldSetName}
            optional={step7.optional}
            label={step7.label}
            style={{ width: '90%' }}
          >
            <P>{step7.description}</P>
            <Checkbox
              name="extraAppliances"
              value="WaterHeater"
              label="Electric water heater"
            />
            <Checkbox
              name="extraAppliances"
              value="PoolOrPondPump"
              label="Pool or pond pump"
            />
            <Checkbox
              name="extraAppliances"
              value="ElectricVehicle"
              label="Electric vehicle"
            />
            <Checkbox
              name="extraAppliances"
              value="SmartThermostat"
              label="Smart thermostat" // TODO: check if this one can be removed
            />
            <Checkbox
              name="extraAppliances"
              value="SolarPanels"
              label="Solar panels"
            />
            <Checkbox
              name="extraAppliances"
              value="ElectricUnderfloorHeating"
              label="Electric underfloor heating"
            />
            <Checkbox
              name="extraAppliances"
              value="GasFireplace"
              label="Gas fireplace"
            />
            <Checkbox
              name="extraAppliances"
              value="HomeStorageBattery"
              label="Home Storage Battery"
            />
          </CheckboxField>
        </fieldset>
      </HomeProfileFormWizardStep>
      <HomeProfileFormWizardStep dataTestId={step8.testId} visible={step === 8}>
        <fieldset>
          <CheckboxField
            label={step8.label}
            style={{ width: '90%' }}
            name={step8.fieldSetName}
            optional={step8.optional}
            error={formError ? 'This is a required question' : null}
          >
            <P>{step8.description}</P>
            <RadioButton
              name="numFridgesOrFreezers"
              value="OneFridgeOrFreezer"
              label="1"
            />
            <RadioButton
              name="numFridgesOrFreezers"
              value="TwoFridgesOrFreezers"
              label="2"
            />
            <RadioButton
              name="numFridgesOrFreezers"
              value="ThreeFridgesOrFreezers"
              label="3"
            />
            <RadioButton
              name="numFridgesOrFreezers"
              value="FourFridgesOrFreezers"
              label="4"
            />
            <RadioButton
              name="numFridgesOrFreezers"
              value="FiveOrMoreFridgesOrFreezers"
              label="5+"
            />
          </CheckboxField>
        </fieldset>
      </HomeProfileFormWizardStep>
    </>
  );
};
