import { AddressField as CPAddressField } from '@ovotech/energy-cx';
import {
  ErrorNotification,
  getErrorMessage,
  P,
  SecondaryCTAButton,
  SelectField,
  Stack,
  TextLink,
} from '@ovotech/nebula';
import { AnalyticsClick } from '@ovotech/ui-tools';
import React, {
  ChangeEvent,
  ComponentProps,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { PostcodeLookupField } from './PostcodeLookupField';
import { useAddressLookupQuery } from '@/src/api/use-address-lookup';
import {
  HOME_MOVES_SWITCH_TO_MANUAL_ENTRY,
  // HOME_MOVES_SWITCH_TO_POSTCODE_LOOKUP,
} from '@/src/constants/analytics-subjects';
import { Address } from '@/src/types/Address';

export const isValidPostcode = (postcode: string) =>
  /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))\s?[0-9][A-Za-z]{2})/gm.test(
    postcode,
  );

type SelectOption = { value: string; label: string };
const placeholderSelectOption = { value: '', label: 'Choose an address' };
export const selectOptionFromData = ({
  address,
  addressLine1,
}: Address): SelectOption => ({
  label: address || addressLine1 || '',
  value: address || addressLine1 || '',
});

type Props = ComponentProps<typeof CPAddressField>;
export const AddressField = ({ id, value, onChange, errors = [] }: Props) => {
  // TODO Quick fix to sort data mapping issue. will be resolved under AQQ-3945
  const [manual, setManual] = useState(true);
  const [localPostcode, setLocalPostcode] = useState('');
  // Local apply to fields specific to this component, not the CP one.
  const [localErrors, setLocalErrors] = useState<
    { id: string; message: ReactNode }[]
  >([]);

  const [selectValue, setSelectValue] = useState('');
  const [selectoptions, setSelectoptions] = useState<SelectOption[]>([]);

  const query = useAddressLookupQuery(localPostcode);
  const [addressLookupData, setAddressLookupData] = useState<Address[]>([]);

  const handleChange =
    (key: string) => (event: ChangeEvent<HTMLInputElement>) =>
      onChange({
        ...value,
        [key]: event.target.value,
      });

  const handleSelectChange = (newValue?: string) => {
    if (!newValue) {
      return;
    }
    setSelectValue(newValue);
    const addressData = addressLookupData.find(
      ({ address, addressLine1 }) =>
        address === newValue || addressLine1 === newValue,
    );

    if (addressData) {
      onChange({
        ...value,
        line1: addressData.addressLine1 || '',
        line2: addressData.addressLine2 || '',
        town: addressData.town || '',
      });
    }
  };

  const onFind = () => {
    setLocalErrors([]);

    if (!isValidPostcode(value.postcode)) {
      setLocalErrors([
        { id: 'postcode', message: 'Enter a valid UK postcode' },
      ]);
      return;
    }

    setLocalPostcode(value.postcode);
  };

  useEffect(() => {
    //Stops looping by checking the data from the API has not changed.
    if (query.status === 'data' && query.data !== addressLookupData) {
      setSelectValue('');
      setAddressLookupData(query.data);

      const filteredData = query.data.filter(
        ({ address, addressLine1 }) => address || addressLine1,
      );

      if (filteredData.length === 0) {
        setSelectoptions([
          { value: 'No addresses found', label: 'No addresses found' },
        ]);
      } else {
        setSelectoptions([
          placeholderSelectOption,
          ...filteredData.map(selectOptionFromData),
        ]);
      }
    }
  }, [query, addressLookupData]);

  useEffect(() => {
    if (
      errors.length &&
      errors.some(error =>
        [`${id}-line1`, `${id}-town`, `${id}-city`].includes(error.id),
      )
    ) {
      setManual(true);
    }
  }, [errors, setManual, id]);

  return (
    <fieldset id={id}>
      {!manual ? (
        <Stack spaceBetween={[3, 6]}>
          {query.status === 'error' ? (
            <ErrorNotification id="address-api-error">
              <P>
                Please try again or{' '}
                <TextLink href={`#${id}-line1`} onClick={() => setManual(true)}>
                  enter your address manually
                </TextLink>
                .
              </P>
            </ErrorNotification>
          ) : null}
          <PostcodeLookupField
            id={`${id}-postcode`}
            label="Postcode"
            error={getErrorMessage('postcode', localErrors)}
            value={value.postcode}
            onChange={handleChange('postcode')}
            onFind={onFind}
            isFetching={query.isFetching}
          />
          <SelectField
            id={`${id}-address`}
            label="Address"
            value={selectValue}
            onValueChange={handleSelectChange}
            options={selectoptions}
            disabled={!selectoptions.length}
          />
          <AnalyticsClick name={HOME_MOVES_SWITCH_TO_MANUAL_ENTRY}>
            <SecondaryCTAButton
              type="button"
              onClick={() => setManual(true)}
              data-testid="address-lookup-manual"
            >
              Is your address not listed?
            </SecondaryCTAButton>
          </AnalyticsClick>
        </Stack>
      ) : (
        <Stack spaceBetween={[3, 6]}>
          <div data-testid="address-lookup-manual-address">
            <CPAddressField
              id={id}
              value={value}
              onChange={e => {
                setSelectValue('');
                onChange(e);
              }}
              errors={errors}
            />
          </div>
          {/*<AnalyticsClick name={HOME_MOVES_SWITCH_TO_POSTCODE_LOOKUP}>*/}
          {/*  <SecondaryCTAButton*/}
          {/*    type="button"*/}
          {/*    onClick={() => setManual(false)}*/}
          {/*    data-testid="address-lookup-return"*/}
          {/*  >*/}
          {/*    Find address from postcode*/}
          {/*  </SecondaryCTAButton>*/}
          {/*</AnalyticsClick>*/}
        </Stack>
      )}
    </fieldset>
  );
};
