import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { SmartChargers } from './anytime-chargers';
import {
  eligibilityResponse,
  IneligibleReasons,
  useEligibilityCheck,
} from './use-eligibility-check';
import {
  ChargeAnytimeApiFetch,
  EligibilityForPayAsYouDrive,
  EligibilityQuestions,
} from './types';
import {
  eligibleForPromo,
  eligibleForVWGPromo,
  PromoType,
} from './anytime-vehicles';
import { getIntegrationType, IntegrationType } from './integrations';
import { useQueryClient } from 'react-query';

interface FeatureFlags {
  isPayAsYouDriveEnabled: boolean;
  isPayAsYouDriveVolvoEnabled: boolean;
  isPayAsYouDriveVWGEnabled: boolean;
  isIndraChargeAnytimeAppEnabled: boolean;
}

interface SmartMeterFlags {
  hasSmartMeter: boolean;
}

export interface chargeAnytimeApi {
  config: {
    devicesEndpointEnabled: boolean;
    eligibilityEndpointEnabled: boolean;
  };
  fetch: ChargeAnytimeApiFetch;
}

interface ChargeAnytimeProps extends FeatureFlags, SmartMeterFlags {
  useCheckEligibilityForPayAsYouDrive(): EligibilityForPayAsYouDrive;
  chargeAnytimeApi: chargeAnytimeApi;
}

export interface ChargeAnytimeContextValue
  extends EligibilityQuestions,
    FeatureFlags,
    SmartMeterFlags {
  setSmartCharger(smartCharger: SmartChargers): void;
  setMake(make: string | null): void;
  setModel(model: string | null): void;
  setYear(year: number | null): void;
  setDeviceId(deviceId: string | null): void;
  setType(type: string | null): void;
  setHasInternetAccess(hasInternetAccess: boolean | null): void;
  setHasSolarPanels(hasSolarPanels: boolean | null): void;
  eligibility: eligibilityResponse & Partial<IneligibleReasons>;
  isError: boolean;
  isFetching: boolean;
  ineligibleNotSmartMeter: boolean;
  ineligibleOnlyGas: boolean;
  ineligibleLoss: boolean;
  ineligibleOther: boolean;
  integrationType: IntegrationType;
  // volvo
  isEligibleVolvo: boolean | PromoType;
  // vwg
  isEligibleVWG: boolean | PromoType;
  vwgOrderNumber: string;
  setVwgOrderNumber(vwgOrderNumber: string): void;
  vwgOrderNumberError: string;
  setVwgOrderNumberError(vwgOrderNumberError: string): void;
  // promo code
  promoCode: string;
  validPromoCode: string;
  setPromoCode: (promoCode: string) => void;
  isPromoCodeError: boolean;
  validatePromoCode(promoCodeCheck: boolean): void;
  chargeAnytimeApi: chargeAnytimeApi;
}

export const ChargeAnytimeContext =
  createContext<ChargeAnytimeContextValue | null>(null);

export const useChargeAnytimeContext = (): ChargeAnytimeContextValue => {
  const contextValue = useContext(ChargeAnytimeContext);
  if (contextValue === null) {
    throw new Error('Must be used inside ChargeAnytime Context');
  }
  return contextValue;
};

export const ChargeAnytimeProvider = ({
  children,
  useCheckEligibilityForPayAsYouDrive,
  hasSmartMeter,
  isPayAsYouDriveVolvoEnabled,
  isPayAsYouDriveEnabled,
  isPayAsYouDriveVWGEnabled,
  isIndraChargeAnytimeAppEnabled,
  chargeAnytimeApi,
}: PropsWithChildren<ChargeAnytimeProps>) => {
  const queryClient = useQueryClient();
  if (!queryClient) {
    throw new Error(
      'ChargeAnytimeProvider must be used within a QueryClientProvider.'
    );
  }

  const [smartCharger, setSmartCharger] = useState<SmartChargers | null>(null);
  const [make, setMake] = useState<string | null>(null);
  const [model, setModel] = useState<string | null>(null);
  const [year, setYear] = useState<number | null>(null);
  const [deviceId, setDeviceId] = useState<string | null>(null);
  const [type, setType] = useState<string | null>(null);
  const [hasInternetAccess, setHasInternetAccess] = useState<boolean | null>(
    null
  );
  const [hasSolarPanels, setHasSolarPanels] = useState<boolean | null>(null);

  const [promoCode, setPromoCode] = useState('');
  const [validPromoCode, setValidPromoCode] = useState('');
  const [isPromoCodeError, setIsPromoCodeError] = useState(false);

  const [vwgOrderNumber, setVwgOrderNumber] = useState('');
  const [vwgOrderNumberError, setVwgOrderNumberError] = useState('');

  const {
    ineligibleNotSmartMeter,
    ineligibleOnlyGas,
    ineligibleLoss,
    isFetching,
    isError,
    ineligibleOther,
  } = useCheckEligibilityForPayAsYouDrive();

  const eligibility = useEligibilityCheck({
    make,
    model,
    year,
    deviceId,
    smartCharger,
    hasSolarPanels,
    hasInternetAccess,
    ineligibleNotSmartMeter,
    ineligibleOnlyGas,
    ineligibleLoss,
    hasSmartMeter,
    ineligibleOther,
    isPayAsYouDriveEnabled,
    isPayAsYouDriveVolvoEnabled,
  });

  const isEligibleVolvo = useMemo(() => {
    return (
      isPayAsYouDriveVolvoEnabled &&
      make !== null &&
      eligibleForPromo(
        deviceId,
        year,
        smartCharger,
        !!hasSolarPanels,
        !!hasInternetAccess,
        {
          isPayAsYouDriveVolvoEnabled,
        }
      )
    );
  }, [
    isPayAsYouDriveVolvoEnabled,
    make,
    model,
    year,
    smartCharger,
    hasSolarPanels,
    hasInternetAccess,
  ]);

  const isEligibleVWG = useMemo(
    () => isPayAsYouDriveVWGEnabled && eligibleForVWGPromo(make, model, year),
    [isPayAsYouDriveVWGEnabled, make, model, year]
  );

  const integrationType = useMemo(
    () =>
      getIntegrationType({
        charger: smartCharger,
        vehicle: { make, model, year },
        hasSolar: !!hasSolarPanels,
        hasVehicleInternet: !!hasInternetAccess,
        featureFlags: {
          payAsYouDriveVolvo: isPayAsYouDriveVolvoEnabled,
          isIndraChargeAnytimeApp: isIndraChargeAnytimeAppEnabled,
        },
      }),
    [
      smartCharger,
      make,
      model,
      year,
      hasSolarPanels,
      hasInternetAccess,
      isPayAsYouDriveVolvoEnabled,
      isIndraChargeAnytimeAppEnabled,
    ]
  );

  const validatePromoCode = useCallback(
    (isPromoCodeValid: boolean) => {
      if (promoCode) {
        setIsPromoCodeError(isPromoCodeValid);
        if (!isPromoCodeValid) {
          setValidPromoCode(promoCode);
        }
      } else {
        setIsPromoCodeError(true);
      }
    },
    [promoCode]
  );

  return (
    <ChargeAnytimeContext.Provider
      value={{
        smartCharger,
        setSmartCharger,
        make,
        setMake,
        model,
        setModel,
        year,
        setYear,
        deviceId,
        setDeviceId,
        type,
        setType,
        hasInternetAccess,
        setHasInternetAccess,
        hasSolarPanels,
        setHasSolarPanels,
        eligibility,
        isError,
        isFetching: !!isFetching,
        isPayAsYouDriveEnabled,
        isPayAsYouDriveVolvoEnabled,
        isPayAsYouDriveVWGEnabled,
        isIndraChargeAnytimeAppEnabled,
        hasSmartMeter,
        ineligibleNotSmartMeter,
        ineligibleOnlyGas,
        ineligibleLoss,
        ineligibleOther,
        integrationType,
        // volvo
        isEligibleVolvo,
        // vwg
        isEligibleVWG,
        vwgOrderNumber,
        setVwgOrderNumber,
        vwgOrderNumberError,
        setVwgOrderNumberError,
        // promo
        validatePromoCode,
        promoCode,
        validPromoCode,
        setPromoCode,
        isPromoCodeError,
        chargeAnytimeApi,
      }}
    >
      {children}
    </ChargeAnytimeContext.Provider>
  );
};
