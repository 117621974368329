export type ProductId =
  | 'c4741d4b-5c45-4394-9064-2ab74f962c65' // Green Electricity
  | 'd8d3661e-17a5-4869-ac68-35f4b31c7894' // Green Gas
  | 'f8c5dd54-ca93-42b8-bc8f-d6a111639fb5' // Green Bundle
  | '1f5c4127-4d0a-461a-8993-bcffaeaf2013' // Polar Plus / BP Pulse
  | 'b0b0424c-8f41-11e8-9eb6-529269fb1459' // EV Bundle
  | 'b0b0424c-8f41-11e8-9eb6-529269fb1458' // OVO Drive
  | 'a376b909-383f-472a-82dd-56a7c04a20b7' // OVO Foundation
  | '748124f4-9917-4697-b3ab-2cd7446ecb47' // BP Pulse
  | 'e02623e2-512f-4787-a8da-c3719c1a275a' // OVO Drive Anytime
  | '3a3f4ab6-6454-49d7-b42f-cb611fe51609' // Pay as You Drive
  | 'b30a0bda-68f7-47d5-bcb1-09ded56ed2aa' // Greener Energy
  | 'b0bdcb8e-0933-4e25-967f-daa8fbc06c77' // GreenerEnergyOneYearFixed
  | '5935a85e-4f40-4ff8-b6fe-ded4632e74f4' // Greener Electricity
  | '3201c3b0-31c5-4d53-81a4-89e3fb4c47f7' // Greener Electricity in 1 Year Fixed + Greener Electricity bundle
  | '373542d9-017c-4f8d-8d7d-9bbf2b36cad7' // Power Move
  | '5a970c0e-a6ee-4334-8c14-24de47f49239' // Heating Control (2 Year Fixed)
  | 'XXXX'; // Unsupported (used for tests)

type ProductStatus = 'Initialized' | 'Active' | 'Cancelled';

type ProductCost = {
  initial: number;
  current: number;
};
type ProductDiscount = {
  percentage: number;
  amount: number;
};

type ProductPromotion = {
  code: string;
  percentage: number;
  amount: number;
  endDate?: string;
  lengthInMonths?: number;
};

type CatalogueProductDiscout = {
  amount: CatalogeProductCostFixed | CatalogeProductCostVariable;
  percentage: number;
};

type CatalogueProductPromotion = {
  code: string;
  percentage: number;
  lengthInMonths?: number;
  amount: CatalogeProductCostFixed | CatalogeProductCostVariable;
};

type ActivatedProductCosting = {
  cost: ProductCost;
  discount?: ProductDiscount;
  promoDiscount?: ProductPromotion;
  signupSource?: string;
};

export type BoltonInstance = ActivatedProductCosting & {
  id?: number;
  name?: string;
  productId: ProductId;
  versionId?: string;
  status: ProductStatus;
  activeFrom: string;
  activeUntil?: string;
  displayName?: string;
};

type BoltonBilling = {
  totalAmount: number;
  discountedAmount: number;
  transactionType: 'Debit' | 'Credit';
  billingCycleDay: 'FirstDayOfMonth' | 'SignupDay';
  billingTerm?: number;
};

export type BoltonInstanceV2 = {
  id: number;
  name: string;
  displayName: string;
  status: ProductStatus;
  productId: ProductId;
  versionId: string;
  activeFrom?: string;
  activeUntil?: string;
  signupSource: SignupSource;
  billing: BoltonBilling;
  discount?: ProductDiscount;
  promotion?: ProductPromotion;
  additionalData?: Record<string, any>;
};

export type BundleInstance = ActivatedProductCosting & {
  name?: string;
  productId: ProductId;
  boltons: Array<BoltonInstance>;
};

export type BundleInstanceV2 = {
  name: string;
  displayName: string;
  productId: ProductId;
  versionId: string;
  constituents: Array<BoltonInstanceV2>;
  discount?: ProductDiscount;
  promoDiscount?: ProductPromotion;
};

type Billing = {
  billingCycleDay: 'FirstDayOfMonth' | 'SignupDay';
  transactionType: 'Debit' | 'Credit';
  vatRate: 'Reduced' | 'OutOfScope' | 'Standard' | 'Exempt' | 'Zero';
  cost: {
    totalAmount: CatalogeProductCostFixed | CatalogeProductCostVariable;
    discountedAmount: CatalogeProductCostFixed | CatalogeProductCostVariable;
  };
};

type CatalogeProductCostFixed = {
  type: 'Fixed';
  value: number;
};
type CatalogeProductCostVariable = {
  type: 'Variable';
  min: number;
  max: number;
};
type CatalogueProductCost = {
  initial: CatalogeProductCostFixed | CatalogeProductCostVariable;
  current: CatalogeProductCostFixed | CatalogeProductCostVariable;
};
type CatalogueProductCosting = {
  cost: CatalogueProductCost;
  discount?: ProductDiscount;
  promoDiscount?: CatalogueProductPromotion;
};

export type IneligibleReason =
  | 'NotAvailableIndividually'
  | 'MissingElectricity'
  | 'MissingGas'
  | 'CannotCancel'
  | 'AlreadyHas'
  | 'Conflict';

type IneligibleReasonV2 =
  | 'Conflict'
  | 'ContractEnd'
  | 'InvalidMeterReadFrequency'
  | 'IncorrectPaymentMechanism'
  | 'Loss'
  | 'NotForSale'
  | 'NotLiable'
  | 'NotSmartMeter';

type EligibilityStatus = {
  eligible: boolean;
  reason?: IneligibleReason;
};

type EligibilityStatusV2 = {
  eligible: boolean;
  reason?: IneligibleReasonV2;
};

export type CatalogueBolton = CatalogueProductCosting & {
  productId: ProductId;
  displayName: string;
  versionId: string;
  eligibility: EligibilityStatus;
  additionalData?: {
    promo: string;
  };
};
type CatalogueBundleBolton = CatalogueProductCosting & {
  productId: ProductId;
  displayName: string;
};
export type CatalogueBundle = CatalogueProductCosting & {
  productId: ProductId;
  displayName: string;
  versionId: string;
  eligibility: EligibilityStatus;
  boltons: Array<CatalogueBundleBolton>;
};

export type CatalogueBoltonV2 = {
  productId: ProductId;
  displayName: string;
  versionId: string;
  eligibility: EligibilityStatusV2;
  billing: Billing;
  catalogueEndDate?: string;
  promoDiscount?: CatalogueProductPromotion;
};
type CatalogueBundleBoltonV2 = {
  productId: ProductId;
  versionId: string;
  displayName: string;
  eligibility: EligibilityStatusV2;
  billing: Billing;
  catalogueEndDate?: string;
  discount?: CatalogueProductDiscout;
};
export type CatalogueBundleV2 = {
  productId: ProductId;
  displayName: string;
  versionId: string;
  eligibility: EligibilityStatusV2;
  discount?: CatalogueProductDiscout;
  boltons: Array<CatalogueBundleBoltonV2>;
};

export enum SignupSource {
  Acquisition = 'Acquisition',
  ManualFix = 'ManualFix',
  OnSupply = 'OnSupply',
  Migrated = 'Migrated',
  Upgrade = 'Upgrade',
  MyOVO = 'MyOVO',
  Loss = 'Loss',
  Renewal = 'Renewal',
}

export type DayOfWeek =
  | 'MONDAY'
  | 'TUESDAY'
  | 'WEDNESDAY'
  | 'THURSDAY'
  | 'FRIDAY';

export type PowerMoveTierV1 = {
  greaterThanTotalPercentage: string;
  lessThanOrEqualTotalPercentage: string;
  monthlyReward: number;
};

export type PowerMoveTierV2 = {
  greaterThanOrEqualTotalPercentage: string;
  lessThanOrEqualTotalPercentage: string;
  monthlyReward: number;
};

export enum CreditingConfigType {
  PeakUsageReduction = 'PeakUsageReduction',
  TieredPeakUsageReduction = 'TieredPeakUsageReduction',
  TieredPeakUsageReductionV2 = 'TieredPeakUsageReductionV2',
  TariffDiscount = 'TariffDiscount',
}

export type PowerMoveCreditingConfig = {
  lessThanTotalPercentage: number;
  monthlyReward: number;
  peakHours: {
    from: string;
    to: string;
  };
  type: CreditingConfigType.PeakUsageReduction;
};

export type TieredPowerMoveCreditingConfig = {
  peakHours: {
    from: string;
    to: string;
  };
  tiers: Array<PowerMoveTierV1 | PowerMoveTierV2>;
  type: CreditingConfigType.TieredPeakUsageReduction;
};

export type TieredPowerMoveCreditingConfigV2 = {
  peakHours: {
    from: string;
    to: string;
  };
  peakDaysOfWeek: DayOfWeek[];
  tiers: Array<PowerMoveTierV1 | PowerMoveTierV2>;
  type: CreditingConfigType.TieredPeakUsageReductionV2;
};

export type AnytimeCreditingConfig = {
  poundsPerkWh: number;
  type: CreditingConfigType.TariffDiscount;
};

export type AvailableProduct = {
  id: ProductId;
  versionId: string;
  displayName: string;
  billing: {
    billingCycleDay: 'FirstDayOfMonth' | 'SignupDay';
    transactionType: 'Debit' | 'Credit';
    billingTerm?: string;
    vatRate: 'Reduced' | 'OutOfScope' | 'Standard' | 'Exempt' | 'Zero';
    cost: {
      totalAmount: CatalogeProductCostFixed | CatalogeProductCostVariable;
      discountedAmount: CatalogeProductCostFixed | CatalogeProductCostVariable;
    };
  };
  discount?: ProductDiscount;
  promoDiscount?: ProductPromotion;
  activeFrom: string;
  creditingConfig?:
    | PowerMoveCreditingConfig
    | TieredPowerMoveCreditingConfig
    | TieredPowerMoveCreditingConfigV2
    | AnytimeCreditingConfig;
  type: string;
};
